import { useDrop } from 'react-dnd';
import React, { useEffect } from 'react';
import { DropTargetWrapper } from './styles';

const DropTarget = ({
  id,
  children,
  className,
  onDrop,
  accept = ['default'],
}) => {
  const [{ isOver, canDrop, getDropResult, getItem }, drop] = useDrop(
    () => ({
      accept,
      drop: onDrop,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        getDropResult: monitor.getDropResult(),
        getItem: monitor.getItem(),
      }),
    }),
    [accept, onDrop],
  );
  const isActive = canDrop && isOver;

  return (
    <DropTargetWrapper
      id={id}
      className={`${className} ${
        isActive ? `active active_${getItem.type}` : ''
      }`}
      ref={drop}
      role="DropTarget"
    >
      {children}
    </DropTargetWrapper>
  );
};
export default DropTarget;
