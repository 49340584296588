import styled from 'styled-components';

export const Heading = styled.h1`
  text-align: center;
  /* height: 90px; */
  margin: 0;
  letter-spacing: 0.08em;
  color: #000;
  @media screen and (max-width: 370px) {
    width: 150px;
    margin: 0 auto;
  }
`;
