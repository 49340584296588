import { useDrag } from 'react-dnd';
import React, { useEffect } from 'react';

const DraggableItem = ({
  dragData,
  children,
  className,
  name,
  type,
  isDropped,
}) => {
  dragData.children = children;
  const [{ opacity, isDragging }, drag] = useDrag(
    () => ({
      item: { name, type, dragData, children },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
        isDragging: monitor.isDragging(),
      }),
      end: (dropResult, monitor) => {},
    }),
    [name, type],
  );
  return (
    <div
      className={className}
      ref={drag}
      role="Draggable"
      style={{
        opacity,
        ...dragData,
        cursor: isDragging ? 'move' : 'grab',
      }}
    >
      {children}
    </div>
  );
};
export default DraggableItem;
