import { sendPageView, trackGtagCustomEvent } from './ga';

export const submitRedCap = (URI, statePostcardMessageData) => {
  trackGtagCustomEvent({
    category: 'nodfrom2050ecard',
    action: 'submit_redcap',
    label: ``,
  });
  const {
    stateMessageFront: front_message,
    stateMessageBack: back_message,
    stateSubtitleFront: front_subtitle,
    stateSubtitleBack: back_subtitle,
  } = statePostcardMessageData;

  const opts = {
    front_subtitle,
    front_message,
    back_subtitle,
    back_message,
    'submit-action': 'Save Record',
  };

  const formData = new FormData();
  Object.keys(opts).forEach((item) => {
    formData.append(item, opts[item]);
  });
  fetch(URI, {
    method: 'post',
    body: formData,
  }).then((res) => {
    console.log(res);
  });
};

export const submitSMTP = (
  URI,
  firstName,
  lastName,
  recipient,
  sender,
  subject,
  imgLoc,
  imgId,
) => {
  trackGtagCustomEvent({
    category: 'nodfrom2050ecard',
    action: 'submit_email',
    label: ``,
  });
  const opts = {
    // fromName: `${toFirstName} ${toLastName}`,
    toEmail: recipient,
    email: recipient,
    fromName: `${firstName} ${lastName}`,
    replyToEmail: sender,
    emailSubject: `2050 ${firstName} Has Sent You A Nod.`,
    subject: `2050 ${firstName} Has Sent You A Nod.`,
    imgLoc,
    shareLoc: `https://nodfrom2050.ca/eng-ca/?qs=${imgId}`,
    emailBody: `<html><head><style>body {font-family: arial, sans-serif;font-size: 12px;}.center {width: 500px;margin: 0 auto;text-align: center;}h3, p { text-align: center; }ul {margin: 0 auto;padding: 0;}ul li {display: inline;padding: 10px;}a {color: #979797;text-decoration: none;font-size: 10px;}</style></head><body><div class=\\"center\\"><img width=\\"423\\" src=\\"${imgLoc}\\" /><h3>#nodfrom2050</h3><p>Please enjoy your Nod from 2050 card!</p><ul><li><a href=\\"#\\">Reply</a></li><li><a href=\\"https://nodfrom2050.ca/eng-ca/qs?${imgId}\\">Create Your Own Card</a></li><li><a href=\\"#\\">Sign up for research with the OTRU</a></li></ul></div></body></html>`,
  };
  console.log(opts);
  fetch(URI, {
    method: 'post',
    body: JSON.stringify(opts),
  }).then((res) => {
    console.log(res);
  });
};
