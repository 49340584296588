import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd';
import { v4 as uuidv4 } from 'uuid';
import Button from '../../ui/Button';

// import { DropTarget, DragDropContainer } from 'react-drag-drop-container';

import DragNDropTarget from './DragNDropTarget';

import {
  Wrapper,
  DesignArea,
  EditArea,
  CardBack,
  CardFront,
  Sprite,
  DraggableDuplicates,
} from './styles';

const DragNDropPrototype = ({}) => {
  const [stateDraggableClonesBack, setStateDraggableClonesBack] = useState([]);
  const [stateDraggableClonesFront, setStateDraggableClonesFront] = useState(
    [],
  );

  const onDrop = (
    item,
    monitor,
    targetDropElement,
    stateDraggableClones,
    setStateDraggableClones,
  ) => {
    const targetDropPosition = document
      .getElementById(targetDropElement)
      .getBoundingClientRect();
    const delta = monitor.getClientOffset();
    console.log(delta.x, delta.y, targetDropPosition.x, targetDropPosition.y);
    const left = Math.round(
      delta.x - targetDropPosition.x - item.dragData.width / 2,
    );
    const top = Math.round(
      delta.y - targetDropPosition.y - item.dragData.height / 2,
    );

    // Move an item if it's already dropped
    // otherwise, add a new duplicate
    const alreadyDropped = item.dragData.id !== undefined;
    if (alreadyDropped) {
      // update
      const updatedState = stateDraggableClones.map((existingItem, index) => {
        const revisedItem =
          existingItem.id === item.dragData.id
            ? { ...existingItem, left, top }
            : existingItem;

        return revisedItem;
      });
      setStateDraggableClones(updatedState);
    } else {
      // create
      const newItem = {
        ...item.dragData,
        left,
        top,
        id: `sticker-${uuidv4()}`,
      };
      setStateDraggableClones(
        stateDraggableClones.length === 0
          ? [newItem]
          : [...stateDraggableClones, newItem],
      );
    }
    return undefined;
  };

  const droppedDesignArea = (item, monitor) => {
    const { dragData, type } = item;
    const { id } = dragData;
    let targetState;
    let targetStateCallback;
    switch (type) {
      case 'sprite_dropped_back':
        targetState = stateDraggableClonesBack;
        targetStateCallback = setStateDraggableClonesBack;
        break;
      case 'sprite_dropped_front':
        targetState = stateDraggableClonesFront;
        targetStateCallback = setStateDraggableClonesFront;
        break;
    }
    if (targetState && targetStateCallback) {
      targetStateCallback(
        targetState.filter((existingItem) => {
          return existingItem.id !== id;
        }),
      );
    }
    return undefined;
  };
  return (
    <Wrapper>
      <DesignArea>
        <CardBack
          className="draggableTargetCardBack"
          id="dropTargetCardBack"
          accept={['sprite_back', 'sprite_dropped_back']}
          onDrop={(item, monitor) => {
            onDrop(
              item,
              monitor,
              'dropTargetCardBack',
              stateDraggableClonesBack,
              setStateDraggableClonesBack,
            );
          }}
        >
          <DraggableDuplicates>
            {stateDraggableClonesBack &&
              stateDraggableClonesBack.map((item, idx) => {
                return (
                  <div>
                    <Sprite
                      dragData={item}
                      className={`sprite_back_${item.id}`}
                      type="sprite_dropped_back"
                    >
                      {item.children}
                    </Sprite>
                  </div>
                );
              })}
          </DraggableDuplicates>
        </CardBack>
        <CardFront
          accept={['sprite_front', 'sprite_dropped_front']}
          id="dropTargetCardFront"
          onDrop={(item, monitor) => {
            onDrop(
              item,
              monitor,
              'dropTargetCardFront',
              stateDraggableClonesFront,
              setStateDraggableClonesFront,
            );
          }}
        >
          <DraggableDuplicates>
            {stateDraggableClonesFront &&
              stateDraggableClonesFront.map((item, idx) => {
                return (
                  <>
                    <Sprite
                      dragData={item}
                      className={`sprite_front_${item.id}`}
                      type="sprite_dropped_front"
                    >
                      {item.children}
                    </Sprite>
                  </>
                );
              })}
          </DraggableDuplicates>
        </CardFront>
      </DesignArea>
      <EditArea
        accept={['sprite_dropped_back', 'sprite_dropped_front']}
        onDrop={(item, monitor) => {
          droppedDesignArea(item, monitor);
        }}
      >
        <Sprite
          className="testSpriteA"
          dragData={{ width: 50, height: 50 }}
          type="sprite_back"
        >
          ♘
        </Sprite>
        <Sprite
          className="testSpriteB"
          dragData={{ width: 50, height: 50 }}
          type="sprite_front"
        >
          ♟︎
        </Sprite>
      </EditArea>
    </Wrapper>
  );
};
DragNDropPrototype.DragNDropPrototype = {};
export default DragNDropPrototype;
