import React from 'react';
import PropTypes from 'prop-types';

import { Heading } from './styles';

const PageTitle = ({ children }) => <Heading>{children}</Heading>;

PageTitle.propTypes = {
  active: PropTypes.bool,
};

PageTitle.defaultProps = {
  active: false,
};

export default PageTitle;
