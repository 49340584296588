import React, { useState, useEffect, createContext } from 'react';
import { graphql } from 'gatsby';
import html2canvas from 'html2canvas';
import { v4 as uuidv4 } from 'uuid';

import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import Header from '../components/ui/Header';
import Footer from '../components/ui/Footer';

import { GlobalStyle } from '../components/globalStyle';
import { submitSMTP, submitRedCap } from '../utils/submit';
import { Container, BackgroundReference } from '../components/layoutComponents';
import SEO from '../components/seo';
import PageTitle from '../components/ui/PageTitle';
import StyledCardCreator from '../components/cardmaker/CardCreator';
import DragNDropPrototype from '../components/cardmaker/DragNDropPrototype';
import Introduction from '../components/cardmaker/Introduction';
import EmailForm from '../components/cardmaker/EmailForm';
import SendConfirmation from '../components/cardmaker/SendConfirmation';
import ShadowDialogs from '../components/ShadowDialogs';
import { getString } from '../utils/translatables';
import { Context } from '../components/context.js';

// import referenceImage from '../images/reference/mobile_introduction_00.png';
// import referenceImage from '../images/reference/mobile_introduction_01.png';
// import referenceImage from '../images/reference/mobile_shadow_00.png';
// import referenceImage from '../images/reference/mobile_shadow_01.png';
// import referenceImage from '../images/reference/mobile_shadow_02.png';
import referenceImage from '../images/reference/mobile_cardCreator_00.png';
// import referenceImage from '../images/reference/mobile_cardCreator_01.png';
// import referenceImage from '../images/reference/mobile_communication_00.png';
// import referenceImage from '../images/reference/mobile_confirmation_00.png';

const IndexPage = ({
  data: {
    prismic: { allSite_configurations, card_maker },
  },
}) => {
  const [stateShowReference, setStateShowReference] = useState(false);

  let isSharePage;
  isSharePage = false;
  let initialImageLocation;
  let initialImageId;
  if (typeof window !== 'undefined' && window) {
    const qs = window.location.search;
    if (qs.indexOf('qs=') > -1) {
      isSharePage = true;
      const imgId = window.location.search.split('qs=')[1].split('&')[0];
      const imgLoc = `https://nod-2050-images.s3.ca-central-1.amazonaws.com/postcards/${
        window.location.search.split('qs=')[1].split('&')[0]
      }.jpg`;
      initialImageLocation = imgLoc;
      initialImageId = imgId;
    }
  }

  const [imgLoc, setImgLoc] = useState(initialImageLocation || '');
  const [imgData, setImgData] = useState('');
  const [imgDataFront, setImgDataFront] = useState('');
  const [imgDataBack, setImgDataBack] = useState('');
  const [imgId, setImgId] = useState(initialImageId || '');
  useEffect(() => {
    if (isSharePage === true) {
      // setImgId(imgId);
      // setImgLoc(imgLoc);
      setActiveStep(4);
    }
  }, []);
  const DEBUG = false;
  // control the current step for the flow
  const [activeStep, setActiveStep] = useState(0);
  const [isValid, setIsValid] = useState(false);

  // save the patch information
  const [patches, setPatches] = useState([]);

  // form values
  const [toFirstName, setToFirstName] = useState('');
  const [toLastName, setToLastName] = useState('');
  const [senderEmail, setSenderEmail] = useState('');
  const [emailMessage, setEmailMessage] = useState('test message');

  const [senderFirstName, setSenderFirstName] = useState('');
  const [senderLastName, setSenderLastName] = useState('');
  const [toEmail, setToEmail] = useState('');

  const [statePostcardMessageData, setStatePostcardMessageData] = useState(
    null,
  );

  const patchAdd = (patch, killId) => {
    if (patch.snap[0] != 0) {
      patch.x = Math.round(patch.x / patch.snap[0]) * patch.snap[0];
    }
    if (patch.snap[1] != 0) {
      patch.y = Math.round(patch.y / patch.snap[1]) * patch.snap[1];
    }

    if (killId) {
      const filteredPatches = patches.filter(function (item) {
        return item.id !== killId;
      });
      setPatches([...filteredPatches, patch]);
      // patches.splice(Number(killId.replace("patch-","")), 1);
    } else {
      setPatches([...patches, patch]);
    }
  };
  const patchRemove = (killId) => {
    const filteredPatches = patches.filter(function (item) {
      return item.id !== killId;
    });
    setPatches([...filteredPatches]);
  };
  const handleSubmit = (imgLoc, imgId) => {
    // if (e) e.preventDefault();
    console.log(
      'allSite_configurations.edges[0].node.email_endpoint,',
      senderFirstName,
      senderLastName,
      toEmail,
      senderEmail,
      emailMessage,
      imgLoc,
      imgId,
    );
    // TODO: verify that all these are tied in correctly
    submitSMTP(
      allSite_configurations.edges[0].node.email_endpoint,
      senderFirstName,
      senderLastName,
      toEmail,
      senderEmail,
      emailMessage,
      imgLoc,
      imgId,
    );
  };

  const toggleMargin = (control, isShadow) => {
    if (!isShadow) {
      if (!control.classList.contains('no-margin'))
        control.classList.add('no-margin');
    } else if (control.classList.contains('no-margin'))
      control.classList.remove('no-margin');
  };

  const toggleDropShadowView = (control, isShadow) => {
    if (!isShadow) {
      if (!control.classList.contains('no-shadow'))
        control.classList.add('no-shadow');
    } else if (control.classList.contains('no-shadow'))
      control.classList.remove('no-shadow');
  };

  const getImageOfCard = () => {
    const cardArea = document.getElementById('design-area');
    const cardFront = document.getElementById('dropTargetCardFront');
    const cardBack = document.getElementById('dropTargetCardBack');
    toggleMargin(cardArea, false);
    window.scrollTo(0, 0);
    // html2canvas(cardArea, { scale: 2 }).then((canvas) => {
    //   const imageData = canvas.toDataURL('image/jpeg', 1.0);
    //   setImgData(imageData);
    //   setActiveStep(3);
    // });
    // html2canvas(cardFront, { scale: 2 }).then((canvas) => {
    //   const imageData = canvas.toDataURL('image/jpeg', 1.0);
    //   setImgDataFront(imageData);
    // });
    // html2canvas(cardBack, { scale: 2 }).then((canvas) => {
    //   const imageData = canvas.toDataURL('image/jpeg', 1.0);
    //   setImgDataBack(imageData);
    // });

    const renderHandler = (renderTarget, stateHandler) => {
      return new Promise((resolve) => {
        html2canvas(renderTarget, { scale: 2 }).then((canvas) => {
          const imageData = canvas.toDataURL('image/jpeg', 1.0);
          resolve({ imageData, callback: stateHandler });
        });
      });
    };
    const renderNod = renderHandler(cardArea, setImgData);
    const renderFront = renderHandler(cardFront, setImgDataFront);
    const renderBack = renderHandler(cardBack, setImgDataBack);
    Promise.all([renderFront, renderBack, renderNod]).then((values) => {
      values.forEach((value) => {
        console.log(value);
        value.callback(value.imageData);
      });
      setActiveStep(3);
    });
  };
  const sendImage = () => {
    // make a call to the s3 bucket to add the design to the system
    const imageId = DEBUG ? 'abc123' : uuidv4();
    const { image_generation_endpoint } = allSite_configurations.edges[0].node;
    setImgId(imageId);
    const opts = {
      imageId,
      imageData: imgData,
    };

    Promise.all([
      fetch(image_generation_endpoint, {
        method: 'post',
        body: JSON.stringify(opts),
      }),
      fetch(image_generation_endpoint, {
        method: 'post',
        body: JSON.stringify({
          imageId: `${imageId}Front`,
          imageData: imgDataFront,
        }),
      }),
      fetch(image_generation_endpoint, {
        method: 'post',
        body: JSON.stringify({
          imageId: `${imageId}Back`,
          imageData: imgDataBack,
        }),
      }),
    ])
      .then(function (responses) {
        // Get a JSON object from each of the responses
        return Promise.all(
          responses.map(function (response) {
            return response.json();
          }),
        );
      })
      .then(function (data) {
        // Log the data to the console
        // You would do something with both sets of data here
        console.log(
          'got a successful response',
          `${data[0].path}${imageId}.jpg`,
        );
        setImgLoc(`${data[0].path}${imageId}.jpg`);
        handleSubmit(`${data[0].path}${imageId}.jpg`, imageId);
      })
      .catch(function (error) {
        // if there's an error, log it
        console.log(error);
      });
  };
  let activeComponent = null;
  let {
    title,
    site_logo_imageSharp: logoSharp,
    site_logo_image: logo,
    site_background_image,
    site_background_imageSharp,
    footer_logo_image,
    footer_logo_imageSharp,
    footer_logo_url,
    footer_message,
  } = allSite_configurations.edges[0].node;

  const handleActiveChange = (step, data) => {
    // if next is clicked, the form is valid , we need to generate the image
    if (step === 3) {
      // get the image data
      // getImageOfCard();
      // if (data) {
      //   setStatePostcardMessageData(data);
      // }
    } else if (step === 4) {
      console.log(statePostcardMessageData);
      submitRedCap(
        allSite_configurations.edges[0].node.form_endpoint,
        statePostcardMessageData,
      );
      sendImage();
    }
    setIsValid(activeStep > step || step === 2);
    setActiveStep(step);
  };
  const handleDialogChange = (dialog) => {};
  switch (activeStep) {
    case -1:
      activeComponent = <DragNDropPrototype />;
      break;
    case 0:
      // title = card_maker.body[activeStep].primary.title;
      title = 'Introduction';
      activeComponent = (
        <Introduction
          data={card_maker.body[activeStep]}
          setIsValid={setIsValid}
          setActiveStep={handleActiveChange}
        />
      );
      break;
    case 1:
      title = 'Card Creator';
      // title = card_maker.body[1].primary.title;
      // activeComponent = (
      //   <StyledCardCreator
      //     setIsValid={setIsValid}
      //     data={
      //       card_maker.body.filter((data) => data.type === 'message_form')[0]
      //     }
      //     setActiveStep={handleActiveChange}
      //     patches={patches}
      //   />
      // );
      break;

    case 3:
      // console.log(card_maker.body[4]);
      title = 'Communication';
      activeComponent = (
        <EmailForm
          isValid={isValid}
          setIsValid={setIsValid}
          data={card_maker.body.filter((data) => data.type === 'share_form')[0]}
          senderFirstName={senderFirstName}
          setSenderFirstName={setSenderFirstName}
          senderLastName={senderLastName}
          setSenderLastName={setSenderLastName}
          toEmail={toEmail}
          setToEmail={setToEmail}
          imgData={imgData}
          setActiveStep={handleActiveChange}
        />
      );
      break;
    case 4:
      title = 'Confirmation';
      // console.log(card_maker.body[5]);
      activeComponent = (
        <SendConfirmation
          data={
            card_maker.body.filter((data) => data.type === 'confirmation')[0]
          }
          imgData={imgData}
          imgDataFront={imgDataFront}
          imgDataBack={imgDataBack}
          img={imgLoc}
          imgId={imgId}
          setActiveStep={handleActiveChange}
          metaDescription={
            allSite_configurations.edges[0].node.meta_description
          }
        />
      );
      break;
    default:
      activeComponent = null;
  }
  return (
    <Context.Provider value={{ dialogResponseHandler: {} }}>
      <DndProvider options={HTML5toTouch}>
        <GlobalStyle siteBG={site_background_imageSharp} />
        <Header
          siteTitle={title}
          logoImage={{ logo, logoSharp }}
          className={`activeStep_${activeStep}`}
        />

        <SEO
          title={title}
          description={allSite_configurations.edges[0].node.meta_description}
          image={imgLoc}
        />
        {activeComponent}
        {(activeStep === 1 || activeStep === 3) && (
          <StyledCardCreator
            setIsValid={setIsValid}
            data={
              card_maker.body.filter((data) => data.type === 'message_form')[0]
            }
            siteBG={site_background_imageSharp}
            setActiveStep={handleActiveChange}
            activeStep={activeStep}
            patches={patches}
            className="cardCreator"
            display={activeStep === 1}
            style={{
              backgroundColor: 'red',
              pointerEvents: activeStep === 3 ? 'none' : '',
            }}
            getImageOfCard={getImageOfCard}
            setStatePostcardMessageData={setStatePostcardMessageData}
          />
        )}
        <Footer
          data={{
            footer_logo_image,
            footer_logo_imageSharp,
            footer_logo_url,
            footer_message,
          }}
          className={`activeStep_${activeStep}`}
        />
        <ShadowDialogs
          data={{
            dialog_postcard_wizard: card_maker.body.filter(
              (data) => data.type === 'dialog_postcard_wizard',
            )[0],
            dialog_stamp_wizard: card_maker.body.filter(
              (data) => data.type === 'dialog_stamp_wizard',
            )[0],
            dialog_messaging_wizard: card_maker.body.filter(
              (data) => data.type === 'dialog_messaging_wizard',
            )[0],
            shadowbox_logo_image:
              allSite_configurations.edges[0].node.shadowbox_logo_image,
            shadowbox_logo_imageSharp:
              allSite_configurations.edges[0].node.shadowbox_logo_imageSharp,
            setActiveStep,
          }}
          className={`activeStep_${activeStep}`}
        />
        <img
          src={referenceImage}
          style={{
            zIndex: 100,
            position: 'fixed',
            top: 0,
            left: 0,
            opacity: 0.75,
            display: stateShowReference ? 'block' : 'none',
            width: '100vw',
            height: '100vh',
          }}
        />
      </DndProvider>
    </Context.Provider>
  );
};

export default IndexPage;

export const query = graphql`
  {
    prismic {
      card_maker(lang: "en-ca", uid: "card-maker") {
        body {
          ... on PRISMIC_Card_makerBodyIntroduction {
            primary {
              title
              bg_image
              bg_imageSharp {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            fields {
              slide_title
              slide_copy
              optInCopy
              label_button_next
              label_button_create
              label_button_create_wizard
            }
          }
          ... on PRISMIC_Card_makerBodyMessage_form {
            type
            primary {
              header_card_back_slide
              header_card_front_slide
              required_field
              label_button_create_wizard
              back_address
              label_back_subtitle
              label_back_message
              label_button_message_wizard
              label_back_stamp
              image_group_stamp {
                ... on PRISMIC_Image_group {
                  _linkType
                  images {
                    image
                    imageSharp {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                }
              }
              label_back_stamp_frame
              image_group_stampframe {
                ... on PRISMIC_Image_group {
                  _linkType
                  images {
                    image
                    imageSharp {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                }
              }
              label_front_image
              image_group_front_image {
                ... on PRISMIC_Image_group {
                  _linkType
                  images {
                    image
                    imageSharp {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                }
              }
              label_front_wallpaper
              image_group_front_wallpaper {
                ... on PRISMIC_Image_group {
                  _linkType
                  images {
                    image
                    imageSharp {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                }
              }
              label_front_sticker
              image_group_front_stickers {
                ... on PRISMIC_Image_group {
                  _linkType
                  images {
                    image
                    imageSharp {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                }
              }
              label_front_subtitle
              label_front_title
              font_label
              color_label
              font_size_label
              label_button_reset
              label_button_back
              label_button_forward
              label_button_create
            }
          }
          ... on PRISMIC_Card_makerBodyShare_form {
            type
            primary {
              first_name
              last_name
              to_email_address
              share_title: title
              required_fields
              preview_text
              button_label_back
              send
            }
          }
          ... on PRISMIC_Card_makerBodyConfirmation {
            type
            primary {
              confirmation_title: title
              page_copy
              share_box_title
              button_label_share_site
              button_label_share_postcard
              button_label_share_front
              button_label_share_back
              button_label_create_another
            }
          }

          ... on PRISMIC_Card_makerBodyDialog_postcard_wizard {
            type
            primary {
              action_copy
              button_cancel
              button_confirm
              select_label
              subtitle
              dialog_title: title
            }
            fields {
              select_option
            }
          }
          ... on PRISMIC_Card_makerBodyDialog_stamp_wizard {
            type
            primary {
              button_cancel
              button_confirm
              button_snap
              color_label
              invert_label
              subtitle
              dialog_title: title
              tune_label
            }
          }
          ... on PRISMIC_Card_makerBodyDialog_messaging_wizard {
            type
            primary {
              adlib_copy
              button_cancel
              button_confirm
              subtitle
              dialog_title: title
            }
          }
        }
      }
      allSite_configurations(lang: "en-ca") {
        edges {
          node {
            title
            image_generation_endpoint
            email_endpoint
            meta_description
            locale
            form_endpoint
            site_logo_image
            site_logo_imageSharp {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            site_background_image
            site_background_imageSharp {
              childImageSharp {
                fixed(width: 1920, height: 1080) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
            footer_logo_image
            footer_logo_imageSharp {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            footer_logo_url
            footer_message
            shadowbox_logo_image
            shadowbox_logo_imageSharp {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
