import styled from 'styled-components';

export const Wrapper = styled.div`
  /* pointer-events: none; */
  position: absolute;
  top: ${(props) => (props.top ? props.top - props.sizes.small : 0)}px;
  left: ${(props) => (props.left ? props.left - props.sizes.small : 0)}px;
  /* width: ${(props) => (props.sizes ? props.sizes.mid : 100)}px; */
  height: ${(props) => (props.sizes ? props.sizes.mid : 100)}px;
  transform: scale(${(props) => (props.scale ? props.scale : 1)})
    rotate(${(props) => (props.rotation ? props.rotation : 0)}deg);
  width: ${(props) => (props.sizes ? props.sizes.large : 150)}px;
  height: ${(props) => (props.sizes ? props.sizes.large : 150)}px;
  margin-top: -${(props) => (props.sizes ? props.sizes.small : 50)}px;
  margin-left: -${(props) => (props.sizes ? props.sizes.small : 50)}px;
  &.placed {
    /* width: ${(props) => (props.sizes ? props.sizes.mid : 100)}px; */
    height: ${(props) => (props.sizes ? props.sizes.mid : 100)}px;
    margin-top: 0px;
    margin-left: 0px;
    img {
      object-fit: contain;
    }
  }
  /* transition:all 0.3s ease-out; */

  /* animation-duration: 0.3s; */
  /* animation-name: dropIn; */
  /* animation-timing-function: ease-out; */
  @keyframes dropIn {
    from {
      /* width: ${(props) => (props.sizes ? props.sizes.large : 150)}px; */
      height: ${(props) => (props.sizes ? props.sizes.large : 150)}px;
      margin-top: -${(props) => (props.sizes ? props.sizes.small : 50)}px;
      margin-left: -${(props) => (props.sizes ? props.sizes.small : 50)}px;
    }

    to {
      /* width: ${(props) => (props.sizes ? props.sizes.mid : 100)}px; */
      height: ${(props) => (props.sizes ? props.sizes.mid : 100)}px;
      margin-top: 0px;
      margin-left: 0px;
    }
  }
`;
