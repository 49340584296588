import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { sendPageView, trackGtagCustomEvent } from '../../../utils/ga';
import { StyledButton } from './styles';

const Button = ({
  dark,
  small,
  disabled,
  handleClick,
  children,
  pageEventAction,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
}) => (
  <StyledButton
    onClick={(e) => {
      if (pageEventAction) {
        trackGtagCustomEvent({
          category: 'nodfrom2050ecard',
          action: pageEventAction,
          label: 'clicked',
        });
      }
      handleClick(e);
    }}
    children={children}
    disabled={disabled}
    ripple
    raised
    onMouseLeave={onMouseLeave}
    onMouseEnter={onMouseEnter}
  />
);

Button.propTypes = {
  siteTitle: PropTypes.string,
  disabled: PropTypes.bool,
  dark: PropTypes.bool,
};

Button.defaultProps = {
  siteTitle: ``,
  disabled: false,
  dark: false,
  small: false,
};

export default Button;
