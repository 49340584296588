/* eslint-env browser */
import PropTypes from 'prop-types';
import React, { useState, useContext, useRef, useEffect } from 'react';
import { RichText, Elements } from 'prismic-reactjs';
import Img from 'gatsby-image';

import { Tooltip } from '@rmwc/tooltip';
import '@rmwc/tooltip/styles';

import { Checkbox } from '@rmwc/checkbox';
import { Switch } from '@rmwc/switch';
import { Slider } from '@rmwc/slider';
import { TextField } from '@rmwc/textfield';
import { CircularProgress } from '@rmwc/circular-progress';
import { Icon } from '@rmwc/icon';

import Button from '../ui/Button';
import ButtonWrapper from '../ui/ButtonWrapper';
import Chipset from '../ui/Chipset';
import TooltipTimer from '../ui/TooltipTimer';

import dialogClose from '../../images/dialogClose.svg';
import dialogCloseOver from '../../images/dialogCloseOver.svg';

import '@rmwc/checkbox/styles';
import '@rmwc/switch/styles';
import '@rmwc/slider/styles';
import '@rmwc/textfield/styles';
import '@rmwc/circular-progress/styles';
import '@rmwc/icon/styles';

import plinthBG from '../../images/plinthDialog.png';

import {
  ShadowWrapper,
  ShadowCurtain,
  DialogWrapper,
  DialogWrapperFill,
  PhotoWrapper,
  VideoWrapper,
  CanvasWrapper,
  DialogClose,
  PlinthImg,
  StyledHeader,
  StyledHeaderTitle,
  StyledArticle,
  StyledTextField,
  UIWrapper,
  RowWrapper,
  RowWrapperCameraButton,
  FieldWrapper,
  ProgressIndicator,
} from './styles';
import { Context } from '../context';

import Img0 from '../../images/linecut_00_400x500.png';
import Img1 from '../../images/linecut_01_400x500.png';
import Img2 from '../../images/linecut_02_400x500.png';
import Img3 from '../../images/linecut_03_400x500.png';
import Img4 from '../../images/linecut_04_400x500.png';
import Img5 from '../../images/linecut_05_400x500.png';
/*eslint-disable */

if (typeof window !== 'undefined') {
  window.linecutImages = [
    new Image(),
    new Image(),
    new Image(),
    new Image(),
    new Image(),
    new Image(),
  ];
  window.linecutImages[0].src = Img0;
  window.linecutImages[1].src = Img1;
  window.linecutImages[2].src = Img2;
  window.linecutImages[3].src = Img3;
  window.linecutImages[4].src = Img4;
  window.linecutImages[5].src = Img5;
}
/* eslint-enable */
const ShadowDialogs = ({
  data: {
    dialog_postcard_wizard: dpw,
    dialog_stamp_wizard: dsw,
    dialog_messaging_wizard: dmw,
    shadowbox_logo_imageSharp,
    setActiveStep,
  },
}) => {
  // linecutImages[Img0, Img1, Img2, Img3, Img4, Img5];
  useEffect(() => {
    setUpdatedDPWFields([
      ...dpw.fields
        .map((item) => {
          return item.select_option;
        })
        .sort(() => Math.random() - 0.5),
    ]);
    // // load the enhance ecommerce plugin
    // function preventBehavior(e) {
    //   e.preventDefault();
    // };
    // if (document) document.addEventListener("touchmove", preventBehavior, {passive: false});
  }, []);

  const [shadowState, showShadow] = useState(false);
  const [dialogState, showDialog] = useState(0);
  const [stateMessageValid, setStateMessageValid] = useState(false);
  const [stateSoftHidePreview, setStateSoftHidePreview] = useState(false);

  const [stateStampThreshold, setStateStampThreshold] = useState(30);
  const [stateStampColor, setStateStampColor] = useState('#8fd3d2');
  const [stateStampInvert, setStateStampInvert] = useState(false);
  const [stateStampPreview, setStateStampPreview] = useState(true);
  const [stateGeneratedImage, setStateGeneratedImage] = useState(false);
  const [cameraActivated, setCameraActivated] = useState(false);
  const [cameraError, setCameraError] = useState(false);
  const [cameraActivationMessage, setCameraActivationMessage] = useState(
    'Starting Camera',
  );
  const [stateEnumeratedDevices, setStateEnumeratedDevices] = useState([]);
  const [
    stateActiveEnumeratedDevice,
    setStateActiveEnumeratedDevice,
  ] = useState(-1);
  const [
    statePostcardWizardSelection,
    setStatePostcardWizardSelection,
  ] = useState(null);

  const [updatedDPWFields, setUpdatedDPWFields] = useState(false);
  const dynamicMessageRef = useRef(null);
  const stampPhotoRef = useRef(null);
  const stampCanvasRef = useRef(null);
  const stampCanvasStaticRef = useRef(null);
  const context = useContext(Context);

  let dynamicInputFields = [];

  context.dialogHandler = (val) => {
    showDialog(val);
    showShadow(true);
    dynamicInputFields = [];
    switch (val) {
      case 0:
        break;
      case 1:
        // Photo wizard
        initCamera();
        break;
      case 2:
        break;
      default:
        break;
    }
  };
  // const POSTCARD_SUCCESS_HANDLER = () => {};
  // const MESSAGING_SUCCESS_HANDLER = () => {};
  // const STAMP_SUCCESS_HANDLER = () => {};
  const toggleShadowState = () => {
    showShadow(!shadowState);
  };
  const propsWithUniqueKey = function (props, key) {
    return Object.assign(props || {}, { key });
  };
  const formatInputElement = (val, props, key) => {
    keyInputIndex += 1;
    // const recursedInput =
    //   val.split('}}').length > 2
    //     ? formatInputElement(val.split('}}')[1], props, key)
    //     : '';

    if (!val) {
      return '';
    }

    const start = val.split('{{', 1)[0];
    const middle = val.substring(val.indexOf('{{') + 2, val.indexOf('}}'));
    const end = val.substring(val.indexOf('}}') + 2, val.length);
    const dynamicInputChange = () => {
      // console.log(e.target.value);
      checkMessageValidity();
    };
    const checkMessageValidity = () => {
      const inputElements = dynamicMessageRef.current.querySelectorAll('input');
      let isValid = true;
      inputElements.forEach((item) => {
        if (item.value === '') {
          isValid = false;
        }
      });
      setStateMessageValid(isValid);
    };
    // const dynamicInput = React.createElement('input', {
    //   type: 'text',
    //   placeholder: middle,
    //   name: `input_${keyInputIndex}`,
    //   key: keyInputIndex,
    //   onChange: dynamicInputChange,
    // });
    const dynamicInput = (
      <StyledTextField
        placeholder={middle}
        name={`input_${keyInputIndex}`}
        key={keyInputIndex}
        onChange={dynamicInputChange}
      />
    );
    dynamicInputFields.push(dynamicInput);
    return React.createElement(
      'span',
      propsWithUniqueKey({ className: 'innnerSpan' }, key),
      start,
      dynamicInput,
      end.indexOf('{{') > -1 ? formatInputElement(end) : end,
    );
  };
  let keyInputIndex = 0;
  const messagingResolver = (type, element, content, children, key) => {
    let props = {};
    switch (type) {
      case Elements.paragraph: {
        // Paragraph
        props = { className: 'paragraph-class' };
        const splitChildren = children.map((child) => {
          return child.map((val) => {
            return typeof val === 'string' && val.indexOf('{{') > -1
              ? React.createElement(
                  'p',
                  propsWithUniqueKey(props, key),
                  formatInputElement(val, props, key),
                )
              : React.createElement('p', propsWithUniqueKey(props, key), val);
          });
        });
        return splitChildren;
      }
      default: {
        // Always include a default that returns null
        return null;
      }
    }
  };
  const sendMessagingResults = (e) => {
    e.preventDefault();
    const refInputs = dynamicMessageRef.current.getElementsByTagName('input');
    // refInputs[0].value;
    const duplicateElement = dynamicMessageRef.current.cloneNode(true);
    duplicateElement.querySelectorAll('label').forEach((item) => {
      item.outerHTML = item.querySelectorAll('input')[0].value;
    });
    duplicateElement.querySelectorAll('p').forEach((item) => {
      item.outerHTML = `${item.innerHTML}\n\n`;
    });
    duplicateElement.querySelectorAll('span').forEach((item) => {
      item.outerHTML = item.innerHTML;
    });
    duplicateElement.querySelectorAll('span').forEach((item) => {
      item.outerHTML = item.innerHTML;
    });
    context.dialogResponseHandler.messaging(duplicateElement.innerHTML);
    toggleShadowState();
  };

  const config = {
    stamp: {
      width: 170,
      height: 204,
    },
    video: {
      width: 204,
      height: 204,
      facingMode: 'user',
    },
  };
  const enumerateCameras = (activeId) => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      return;
    }

    // List cameras and microphones.

    navigator.mediaDevices
      .enumerateDevices()
      .then(function (devices) {
        let activeMatch = -1;
        setStateEnumeratedDevices(
          devices
            .filter((item) => {
              return item.kind === 'videoinput';
            })
            .map((item, index) => {
              // console.log(activeId, item.deviceId);

              if (item.deviceId === activeId) {
                activeMatch = index;
              }
              return { label: item.label, deviceId: item.deviceId };
            }),
        );
        if (activeMatch > -1) {
          console.log(activeMatch);
          setStateActiveEnumeratedDevice(activeMatch);
        }
      })
      .catch(function (err) {
        console.log(`${err.name}: ${err.message}`);
      });
  };
  const initCamera = (deviceId) => {
    // Get access to the camera!
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            ...config.video,
            deviceId: { exact: deviceId },
          },
        })
        .then(function (stream) {
          stampPhotoRef.current.srcObject = stream;
          stampPhotoRef.current.play();

          if (cameraActivated === false) {
            const activeId = stream
              .getTracks()
              .filter((track) => track.kind === 'video')
              .map((track) => {
                return track.getSettings().deviceId;
              });
            enumerateCameras(activeId ? activeId[0] : null);
          }

          setCameraActivated(true);
        })
        .catch(function (err) {
          setCameraError(true);
          setCameraActivationMessage(
            "Can't activate your camera.\nPlease try a stamp instead",
          );
        });
    }
  };

  const Filters = {
    createImage(w, h) {
      const can = document.createElement('canvas');
      can.width = w;
      can.height = h;
      return can;
    },
    copyImage(img) {
      console.log(
        img.width,
        img.height,
        config.stamp.width,
        config.stamp.height,
      );
      const image = this.createImage(img.width, img.height);
      const ctx = image.getContext('2d');
      if (img instanceof ImageData) {
        ctx.putImageData(img, 0, 0);
      } else {
        ctx.drawImage(img, 0, 0, img.width, img.height);
      }
      return image;
    },
    getPixels(img) {
      if (!(img instanceof HTMLCanvasElement)) {
        img = copyImage(img);
      }
      const ctx = img.getContext('2d');
      return ctx.getImageData(0, 0, img.width, img.height);
    },
    threshold(pixels, threshold, light = [255, 255, 255], dark = [0, 0, 0]) {
      // light, dark arrays of RGB
      const d = pixels.data;
      let i = 0;
      let l = d.length;
      while (l-- > 0) {
        const v = d[i] * 0.2126 + d[i + 1] * 0.7152 + d[i + 2] * 0.0722;
        [d[i], d[i + 1], d[i + 2]] = v >= threshold ? light : dark;
        i += 4;
      }
      return pixels;
    },
  };
  const takePhoto = () => {
    setStateGeneratedImage(true);
    const ctx = stampCanvasRef.current.getContext('2d');
    const staticCtx = stampCanvasStaticRef.current.getContext('2d');
    staticCtx.drawImage(stampPhotoRef.current, 0, 0);
  };
  const updateCanvas = () => {
    const ctx = stampCanvasRef.current.getContext('2d');
    const staticCtx = stampCanvasStaticRef.current.getContext('2d');

    ctx.drawImage(stampCanvasStaticRef.current, 0, 0);

    ctx.globalCompositeOperation = 'source-over';
    ctx.clearRect(0, 0, config.stamp.width, config.stamp.height);
    for (let i = 0; i < 6; i++) {
      const thresholdAmount = i * stateStampThreshold;

      const pixels = Filters.getPixels(stampCanvasStaticRef.current);

      const layerCanvas = document.createElement('canvas');
      layerCanvas.width = config.stamp.width;
      layerCanvas.height = config.stamp.height;
      const layerCtx = layerCanvas.getContext('2d');
      if (stateStampInvert === true) {
        layerCtx.globalCompositeOperation = 'difference';
        layerCtx.fillStyle = 'white';
        layerCtx.fillRect(0, 0, config.stamp.width, config.stamp.height);
      }
      Filters.threshold(pixels, thresholdAmount);
      const thresholdImage = Filters.copyImage(pixels);
      const offsetX = 0; // - (config.stamp.width / 2 - config.stamp.width / 2);
      const offsetY = 0; // - (config.stamp.height / 2 - config.stamp.height / 2);
      layerCtx.drawImage(thresholdImage, offsetX, offsetY);
      layerCtx.globalCompositeOperation = 'difference';
      layerCtx.fillStyle = 'white';
      layerCtx.fillRect(0, 0, config.stamp.width, config.stamp.height);
      layerCtx.globalCompositeOperation = 'destination-in';

      if (typeof window !== 'undefined') {
        layerCtx.drawImage(window.linecutImages[i], 0, 0);
      }
      ctx.globalCompositeOperation = 'source-over';
      ctx.drawImage(layerCanvas, 0, 0);
    }
    ctx.globalCompositeOperation = 'difference';
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, config.stamp.width, config.stamp.height);
    ctx.globalCompositeOperation = 'color';
    ctx.fillStyle = stateStampColor;
    ctx.fillRect(0, 0, config.stamp.width, config.stamp.height);
  };
  useEffect(() => {
    if (stampCanvasRef.current && stampCanvasStaticRef.current) {
      updateCanvas();
    }
  }, [stateStampInvert, stateStampColor, stateStampThreshold]);
  const dialogStateClassname =
    dialogState === 0
      ? 'dialog_dpw'
      : dialogState === 1
      ? 'dialog_dsw'
      : 'dialog_dmw';
  useEffect(() => {
    if (
      context.dialogResponseHandler.postcard &&
      dialogState === 0 &&
      shadowState === true
    ) {
      context.dialogResponseHandler.postcard(statePostcardWizardSelection);
      showShadow(false);
    }
  }, [context]);
  return (
    <ShadowWrapper
      className={`${
        shadowState === true ? 'active' : ''
      } ${dialogStateClassname}`}
    >
      <ShadowCurtain onClick={toggleShadowState} />
      <DialogWrapper className="dialogWrapper">
        <DialogWrapperFill>
          <DialogClose onClick={toggleShadowState}>
            <img src={dialogClose} className="idle" />
            <img src={dialogCloseOver} className="over" />
          </DialogClose>
          <PlinthImg
            src={plinthBG}
            className={`plinthBG_${dialogStateClassname}`}
          />

          <StyledHeader className="styledHeader">
            <Img
              className="logo"
              fluid={shadowbox_logo_imageSharp.childImageSharp.fluid}
              objectFit="contain"
            />
            {dialogState === 0 && (
              <StyledHeaderTitle>
                {RichText.render(dpw.primary.dialog_title)}
                {RichText.render(dpw.primary.subtitle)}
              </StyledHeaderTitle>
            )}

            {dialogState === 1 && (
              <StyledHeaderTitle>
                {RichText.render(dsw.primary.dialog_title)}
                {RichText.render(dsw.primary.subtitle)}
              </StyledHeaderTitle>
            )}

            {dialogState === 2 && (
              <StyledHeaderTitle>
                {RichText.render(dmw.primary.dialog_title)}
                {RichText.render(dmw.primary.subtitle)}
              </StyledHeaderTitle>
            )}
          </StyledHeader>
          <StyledArticle>
            {dialogState === 0 && (
              <>
                {/* "Help Me" Wizard */}
                {RichText.render(dpw.primary.select_label)}
                <Chipset
                  label={RichText.asText(dpw.primary.select_label)}
                  choice
                  onHandleInteraction={(e, value) => {
                    setStatePostcardWizardSelection(value);
                  }}
                  options={updatedDPWFields}
                />
                <div className="action_copy">
                  {RichText.render(dpw.primary.action_copy)}
                </div>
                <ButtonWrapper>
                  <Button
                    handleClick={toggleShadowState}
                    pageEventAction="shadow_helpmenod_button_cancel"
                  >
                    {dpw.primary.button_cancel}
                  </Button>
                  <Button
                    disabled={statePostcardWizardSelection === null}
                    pageEventAction="shadow_helpmenod_button_confirm"
                    ripple={false}
                    handleClick={(e) => {
                      e.preventDefault();
                      if (context.dialogResponseHandler.postcard) {
                        context.dialogResponseHandler.postcard(
                          statePostcardWizardSelection,
                        );
                        toggleShadowState();
                      } else {
                        setActiveStep(1);
                      }
                    }}
                  >
                    {dpw.primary.button_confirm}
                  </Button>
                </ButtonWrapper>
              </>
            )}
            {dialogState === 1 && (
              <>
                {/* Stamp Wizard */}
                <RowWrapper className="rowFields">
                  <PhotoWrapper
                    width={config.stamp.width}
                    height={config.stamp.height}
                  >
                    {cameraActivated !== true && (
                      <ProgressIndicator>
                        {cameraError === false && (
                          <CircularProgress size="large" />
                        )}
                        <label>{cameraActivationMessage}</label>
                      </ProgressIndicator>
                    )}
                    <CanvasWrapper className="staticImage">
                      <canvas
                        width={config.stamp.width}
                        height={config.stamp.height}
                        ref={stampCanvasStaticRef}
                      />
                    </CanvasWrapper>
                    <VideoWrapper
                      ref={stampPhotoRef}
                      autoPlay
                      playsInline
                      muted
                      id="stampVideo"
                      width={config.video.width}
                      height={config.video.height}
                    >
                      <source />
                    </VideoWrapper>
                    <CanvasWrapper
                      onClick={() => {
                        if (cameraActivated === true) {
                          takePhoto();
                          updateCanvas();
                        }
                      }}
                      className={`${stateStampPreview ? '' : 'hide'} ${
                        stateSoftHidePreview ? 'softHide' : ''
                      }`}
                    >
                      <canvas
                        width={config.stamp.width}
                        height={config.stamp.height}
                        ref={stampCanvasRef}
                      />
                    </CanvasWrapper>
                  </PhotoWrapper>
                  <UIWrapper>
                    <RowWrapperCameraButton>
                      <FieldWrapper>
                        {stateActiveEnumeratedDevice > -1 &&
                          stateEnumeratedDevices.length > 1 && (
                            <>
                              <TooltipTimer
                                content={
                                  stateEnumeratedDevices[
                                    stateActiveEnumeratedDevice
                                  ].label.split(' (')[0]
                                }
                                updateOnChange
                                time={1000}
                              >
                                <Button
                                  handleClick={(e) => {
                                    e.preventDefault();
                                    const nextDeviceIndex =
                                      (stateActiveEnumeratedDevice + 1) %
                                      stateEnumeratedDevices.length;
                                    initCamera(
                                      stateEnumeratedDevices[nextDeviceIndex]
                                        .deviceId,
                                    );
                                    setStateActiveEnumeratedDevice(
                                      nextDeviceIndex,
                                    );
                                  }}
                                  icon="cameraswitch"
                                >
                                  <Icon icon="cameraswitch" />
                                </Button>
                              </TooltipTimer>
                            </>
                          )}
                      </FieldWrapper>
                      <FieldWrapper>
                        <input
                          type="color"
                          value={stateStampColor}
                          onChange={(e) => {
                            setStateStampColor(e.target.value);
                          }}
                          disabled={cameraActivated !== true}
                        />
                        <label>{dsw.primary.color_label}</label>
                      </FieldWrapper>
                    </RowWrapperCameraButton>
                    <FieldWrapper>
                      <Switch
                        checked={stateStampInvert}
                        onChange={(e) => {
                          setStateStampInvert(!!e.currentTarget.checked);
                        }}
                        label={dsw.primary.invert_label}
                        disabled={cameraActivated !== true}
                      />
                    </FieldWrapper>
                    <FieldWrapper className="tuneSlider">
                      <Slider
                        onInput={(e) => {
                          setStateStampThreshold(e.target.value);
                        }}
                        onChange={(e) => {
                          setStateStampThreshold(e.target.value);
                        }}
                        value={stateStampThreshold}
                        disabled={cameraActivated !== true}
                      />
                      <label>{dsw.primary.tune_label}</label>
                    </FieldWrapper>

                    <FieldWrapper>
                      <Switch
                        checked={stateStampPreview}
                        onChange={(e) => {
                          setStateStampPreview(!!e.currentTarget.checked);
                        }}
                        label="Preview"
                        className="checkboxPreview"
                        disabled={cameraActivated !== true}
                      />
                    </FieldWrapper>
                  </UIWrapper>
                </RowWrapper>
                <ButtonWrapper>
                  <Button
                    handleClick={() => {
                      takePhoto();
                      updateCanvas();
                    }}
                    onMouseEnter={() => {
                      setStateSoftHidePreview(true);
                    }}
                    onMouseLeave={() => {
                      setStateSoftHidePreview(false);
                    }}
                    disabled={cameraActivated !== true}
                    pageEventAction="shadow_stampgenerator_button_takephoto"
                  >
                    {dsw.primary.button_snap}
                  </Button>
                  <Button
                    handleClick={toggleShadowState}
                    pageEventAction="shadow_stampgenerator_button_cancel"
                  >
                    {dsw.primary.button_cancel}
                  </Button>
                  <Button
                    ripple={false}
                    handleClick={(e) => {
                      e.preventDefault();

                      context.dialogResponseHandler.stampPhoto(
                        stampCanvasRef.current.toDataURL(),
                      );
                      toggleShadowState();
                    }}
                    disabled={!stateGeneratedImage}
                    pageEventAction="shadow_stampgenerator_button_confirm"
                  >
                    {dsw.primary.button_confirm}
                  </Button>
                </ButtonWrapper>
              </>
            )}

            {dialogState === 2 && (
              <>
                {/* Messaging Helper Wizard */}
                <div ref={dynamicMessageRef} className="dynamicInputWrapper">
                  <RichText
                    render={dmw.primary.adlib_copy}
                    htmlSerializer={messagingResolver}
                  />
                </div>

                <ButtonWrapper>
                  <Button
                    handleClick={toggleShadowState}
                    pageEventAction="shadow_helpmewrite_button_cancel"
                  >
                    {dmw.primary.button_cancel}
                  </Button>
                  <Button
                    ripple={false}
                    disabled={!stateMessageValid}
                    handleClick={sendMessagingResults}
                    pageEventAction="shadow_helpmewrite_button_confirm"
                  >
                    {dmw.primary.button_confirm}
                  </Button>
                </ButtonWrapper>
              </>
            )}
          </StyledArticle>
        </DialogWrapperFill>
      </DialogWrapper>
    </ShadowWrapper>
  );
};
ShadowDialogs.propTypes = {
  data: PropTypes.object,
};
ShadowDialogs.defaultProps = {
  data: ``,
};

export default ShadowDialogs;
// <DialogPostcardWizard />
// <DialogStamp />
// <DialogMessaging />
