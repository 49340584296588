import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Chip, ChipSet } from '@rmwc/chip';
import '@rmwc/chip/styles';

import { sendPageView, trackGtagCustomEvent } from '../../../utils/ga';
import { ChipSetWrapper } from './styles';

const Chipset = ({
  disabled,
  handleClick,
  options,
  label,
  onMouseEnter = () => {},
  onMouseLeave = () => {},
  choice = true,
  onHandleInteraction = () => {},
  selectedIndex = -1,
}) => {
  const [stateSelected, setStateSelected] = useState(selectedIndex);
  const handleInteraction = (e, value, index) => {
    trackGtagCustomEvent({
      category: 'nodfrom2050ecard',
      action: 'chip_clicked',
      label: value,
    });
    onHandleInteraction(e, value, index);
  };
  return (
    <ChipSetWrapper>
      <ChipSet choice={choice} filter={!choice}>
        {options &&
          options.map((option, index) => {
            return (
              <Chip
                checkmark
                selected={stateSelected === index}
                onInteraction={(e) => {
                  setStateSelected(index);
                  handleInteraction(e, option, index);
                }}
                key={index}
                label={`${option}`}
              />
            );
          })}
      </ChipSet>
    </ChipSetWrapper>
  );
};

Chipset.propTypes = {};

Chipset.defaultProps = {};

export default Chipset;
