import styled, { keyframes } from 'styled-components';
import DraggableItem from '../DraggableItem';

export const Wrapper = styled.div`
  /* max-width: 865px; */
  width: 100%;
  margin: 1rem auto;
  overflow: auto;
  white-space: nowrap;
  display: flex;
  max-width: Calc(50vw - 3.875rem - var(--margin-lr));
  @media screen and (max-width: 1024px) {
    max-width: Calc(50vw - 1rem - var(--margin-lr) * 3.5);
  }
  @media screen and (max-width: 768px) {
    width: Calc(100vw - 4rem);
    max-width: 100%;
  }
  img {
    pointer-events: none;
  }
`;

export const Carousel = styled.div`
  width: ${(props) => (props.scroll ? '90%' : '100%')};
  min-width: 423px;
  /* height: 80px; */
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    min-width: unset;
  }
`;

export const Slider = styled.div`
  position: relative;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  transition: all ease-out 0.5s;
  left: ${(props) =>
    props.offsetLeft ? props.offsetLeft : -props.page * props.panelSize}px;
  margin: 0 auto;
  > div {
    float: left;
  }
`;

export const Slide = styled.div`
  width: 100px;
  height: 80px;
  margin: 10px;
  border-radius: 3px;
  float: left;
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  transition: all 2s;
`;
export const AnimationCheckmark = keyframes`
  from {
    width: 0;
    height: 0;
  }
  to {
    width: 100%;
    height: 100%;
  }
`;
export const StickerCheckmark = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 0;
  max-height: 0;
  transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
`;
export const Sticker = styled(DraggableItem)`
  position: relative;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0);
  /* width: ${(props) => (props.sizes ? props.sizes.width : '50')}px; */
  height: ${(props) => (props.sizes ? props.sizes.height : '50')}px !important;
  padding: 0;
  display: inline-block;
  text-align: center;
  margin: 0 ${(props) => (props.sizes ? 22 : 5)}px;
  text-decoration: none;
  background: #eee;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.35);
  &:last-child {
    margin-right: 0;
  }

  &:first-child {
    margin-left: 0;
  }
  > div {
    width: ${(props) =>
      props.sizes ? props.sizes.width * props.aspect : '50'}px;
    margin: 0 auto;
  }
  &:hover {
    /* position: absolute;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%; */
  }
  img:first-child,
  > div.gatsby-image-wrapper {
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }
  &.active {
    border-color: #6f3ce0;
    ${StickerCheckmark} {
      max-width: 34px;
      max-height: 34px;
    }
  }
`;

export const CarouselButton = styled.a`
  /* display: block; */
  margin-top: 0;
  /* color: black; */
  cursor: pointer;
  max-width: 5%;
  width: 100%;
  position: relative;
  img {
    display: block;
    left: 50%;
    top: 50%;
    position: absolute;
    filter: brightness(150%) contrast(1%) grayscale(1);
    height: 15px;
    margin: 0;
    transform: translate(-50%, -50%);
  }
  &.disabled {
    opacity: 0.1;
    pointer-events: none;
  }
`;
