import styled, { keyframes } from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import carouselSlideBG from '../../../images/plinth.png';
import introductionArticleBG from '../../../images/introduction_body_bg.png';
import plinthMask from '../../../images/plinthIntroductionMask.png';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: Calc(100vh - 2.5 * var(--margin-tb));
  height: auto;
  .checkboxOptin {
    display: flex;
    align-items: start;
    .mdc-checkbox__ripple:before {
      display: none;
    }
  }
  .buttonWrapper {
    margin-left: 0;
    margin-right: Calc(var(--margin-lr) * 0.25);
    @media screen and (max-width: 1024px) {
      margin-left: unset;
      margin-right: unset;
    }
    @media screen and (max-width: 768px) {
    }
  }
  label {
    color: #000;
  }
`;
export const CarouselArea = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background: ${(props) =>
    props.currentPage !== 1 || props.isIOS === true
      ? `URL(${carouselSlideBG}) no-repeat 75% 172px;`
      : `background: none;`}

  background-position: left Calc(75% + var(--margin-lr) * 2) top 172px;
  margin-bottom: Calc(var(--margin-tb) * 2);
  @media screen and (max-width: 1024px) {
    background-size: 13.9vw 17.57vw;
    background-position: left Calc(71% + var(--margin-lr) * 2) top 80px;
  }
  @media screen and (max-width: 768px) {
    background-size: 29.7vw 36.8vw;
    background-position: right Calc(var(--margin-lr)) top 28.5%;
  }
`;

export const WrapperCarousel = styled.div`
  width: ${(props) => props.slideLength * 100}%;
  left: ${(props) => props.currentPage * -100}%;
  position: relative;
  margin: 0 auto;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  /* @media screen and (max-width: 768px) {
    display: block;
    overflow-y: auto;
    padding: 1rem 0;
  } */
  transition: all 0.25s ease-out;
`;
const transistionVideoIn = keyframes`
from{
  transform: translate(-50%, -50%) scale(0);
}
to{
  transform: translate(-50%, -50%) scale(1);
}`;
export const VideoPlinthElement = styled.video`
  position: absolute;
  top: 310px;
  left: Calc(76.5%);
  transform: translate(-50%, -50%) scale(0);
  /* transform: translate(-50%, -50%) scale(1); */
  animation: ${transistionVideoIn} 1s 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
  animation-fill-mode: forwards;
  z-index: 0;
  @media screen and (max-width: 1024px) {
    top: 170px;
    left: 70%;
    height: 190px;
  }
  @media screen and (max-width: 768px) {
    top: 250px;
    left: 79%;
    height: 150px;
  }
`;
export const VideoMask = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  mix-blend-mode: hue;
  background: #000;
  opacity: 0;
  transition: opacity 0.5s ease-in, clip-path 0.5s ease-in;
  &.isLoaded {
    opacity: 0.35;
  }

  &.slide_1 {
    opacity: 0;
    clip-path: polygon(
      50% 0,
      50% -20vh,
      Calc(50% - 54vh) Calc(15vh),
      Calc(50% - 54vh) Calc(75vh),
      50% Calc(110vh),
      Calc(50% + 54vh) Calc(75vh),
      Calc(50% + 54vh) Calc(15vh),
      50% -20vh,
      50% 0,
      100% 0,
      100% 100%,
      0 100%,
      0 0,
      50% 0
    );
  }
  clip-path: polygon(
    50% 0,
    50% 5vh,
    Calc(50% - 34vh) Calc(35vh),
    Calc(50% - 34vh) Calc(70vh),
    50% Calc(85vh),
    Calc(50% + 34vh) Calc(70vh),
    Calc(50% + 34vh) Calc(35vh),
    50% 5vh,
    50% 0,
    100% 0,
    100% 100%,
    0 100%,
    0 0,
    50% 0
  );
  @media screen and (max-width: 1024px) {
    height: Calc(100vh - var(--margin-tb) - 175px);
    max-height: 100vh;
    min-height: 250px;

    clip-path: polygon(
      50% 0,
      50% 5vh,
      Calc(50% - 25.5vh) Calc(26.25vh),
      Calc(50% - 25.5vh) Calc(52.5vh),
      50% Calc(65vh),
      Calc(50% + 25.5vh) Calc(52.5vh),
      Calc(50% + 25.5vh) Calc(26.25vh),
      50% 5vh,
      50% 0,
      100% 0,
      100% 100%,
      0 100%,
      0 0,
      50% 0
    );
  }
  @media screen and (max-width: 768px) {
    max-height: 51.4vh;

    clip-path: polygon(
      50% 0,
      50% 5vh,
      Calc(50% - 17vh) Calc(17.5vh),
      Calc(50% - 17vh) Calc(35vh),
      50% Calc(42.5vh),
      Calc(50% + 17vh) Calc(35vh),
      Calc(50% + 17vh) Calc(17.5vh),
      50% 5vh,
      50% 0,
      100% 0,
      100% 100%,
      0 100%,
      0 0,
      50% 0
    );
  }
`;
export const VideoElementWrapper = styled.div`
  width: 100%;
  height: 100vh;
  max-width: Calc(50vw + 30px);
  max-height: Calc(100vh - var(--margin-tb) * 2);
  position: fixed;
  overflow: hidden;
  @media screen and (max-width: 1024px) {
    /* max-height: Calc(100vh - var(--margin-tb) - 175px); */
    height: Calc(100vh - var(--margin-tb) - 175px);
    max-height: 100vh;
    min-height: 250px;
  }
  @media screen and (max-width: 768px) {
    max-width: 60vw;
    max-height: 51.4vh;
    left: 0;
    top: 100px;
  }
`;
export const VideoElement = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export const CarouselSlide = styled.div`
  position: relative;
  width: 100%;
  h1 {
    width: 100%;
    display: inline-block;
    margin-top: 255px;
    margin-left: 2rem;
    text-align: center;
    @media screen and (max-width: 1024px) {
      margin-left: 0;
      margin-top: 100px;
    }
    @media screen and (max-width: 768px) {
      width: 75vw;
      margin-left: 15vw;
      text-align: left;
      margin-bottom: 55px;
      margin-top: 115px;
    }
  }
  p {
    font-style: italic;
    margin-bottom: 2.25rem;
    @media screen and (max-width: 1024px) {
      margin-bottom: 1.125rem;
    }
  }
  &.slide_0 {
    p {
      margin-bottom: 4rem;
      @media screen and (max-width: 1024px) {
        margin-bottom: 2.75rem;
      }
      @media screen and (max-width: 768px) {
        margin-bottom: 3rem;
      }
    }
    .buttonWrapper {
      justify-content: flex-end;
    }
  }
  &.slide_1 {
  }
  @media screen and (max-width: 768px) {
    &.slide_1 {
      .buttonWrapper {
        flex-direction: column;
        align-items: flex-start;
        button {
          margin: 0.5rem 0;
        }
      }
    }
  }
`;
export const StyledArticle = styled.article`
  margin-left: Calc(50% + var(--margin-lr) * 3.5);
  margin-right: Calc(var(--margin-lr) * 2.75);
  @media screen and (max-width: 1024px) {
    margin-left: Calc(100vw - 43.75vw - 6.0625rem);
    margin-right: 6.0625rem;
    width: 43.75vw;
    background-image: url(${introductionArticleBG});
    background-color: transparent;
    padding: 3rem 2.4375rem Calc(var(--margin-tb) * 0.5) 3rem;
    position: relative;
    z-index: 2;
  }
  @media screen and (max-width: 768px) {
    width: 80vw;
    margin-left: 15vw;
    margin-right: unset;
    padding: 15px 13px 13px 22px;
  }
`;
export const Container = styled.div`
  min-width: 400px;
  min-height: 400px;
  max-width: 870px;
  max-height: 870px;
  width: 40%;
  height: 40%;
  pointer-events: none;
  position: fixed;
  right: 0;
  bottom: 0;
  overflow: hidden;
  > div {
    left: 70px;
    top: 140px;
  }
  @media screen and (max-width: 2000px) {
    display: none;
  }
`;
export const UIWrapper = styled.div`
  margin-top: 0;
`;
export const InputCheckbox = styled.div`
  position: relative;
  min-width: 18px;
`;
