// A sticker is made of react dnd
// DraggableItem - revised from previous
// versions

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { v4 as uuidv4 } from 'uuid';
import { sendPageView, trackGtagCustomEvent } from '../../../utils/ga';

import {
  Wrapper,
  Sticker,
  StickerCheckmark,
  DragSticker,
  Carousel,
  Slider,
  CarouselButton,
  StickerGIF,
  DragStickerGIF,
} from './styles.js';
import larrow from '../../../images/larrow.png';
import rarrow from '../../../images/rarrow.png';
import checkmark from '../../../images/Checkmark.png';

const StickerPicker = ({
  snap,
  patches,
  sizes,
  numPatchesPerPanel,
  scroll,
  selectable,
  type,
  targetKey,
  onToggleSticker,
  contain,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [stateSelectedIndex, setStateSelectedIndex] = useState(-1);

  // const numPatches = 7; //Math.floor(423 / 60);
  const groupedPatches = [];

  const sliderClassName = uuidv4();

  let i;
  let j = 10;
  for (i = 0, j = patches.length; i < j; i += numPatchesPerPanel) {
    groupedPatches.push(patches.slice(i, i + numPatchesPerPanel));
  }
  const patchPadding = 22;
  const handleNext = (e) => {
    e.preventDefault();

    const offsetAmount = document
      .getElementById('carousel')
      .getBoundingClientRect().width;
    const maxLeft = -(panelSize - offsetAmount);
    setOffsetLeft(Math.max(-(currentPage + 1) * offsetAmount, maxLeft));
    if (Math.max(-(currentPage + 1) * offsetAmount) > maxLeft) {
      setCurrentPage(currentPage + 1);
    }

    // updateButtonNextState((document.getElementsByClassName(sliderClassName)[0].getBoundingClientRect().right - panelSize), e.pageX);
    updateButtonNextState(-(currentPage + 1) * offsetAmount, maxLeft);
  };

  const handlePrev = (e) => {
    e.preventDefault();
    const offsetAmount = document
      .getElementById('carousel')
      .getBoundingClientRect().width;
    setOffsetLeft(Math.min(-(currentPage - 1) * offsetAmount, 0));
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
    updateButtonNextState(100, 0);
  };

  const panelSize = (sizes.width + patchPadding * 2) * patches.length;
  const [buttonNextState, setButtonNextState] = useState(
    currentPage < groupedPatches.length - 1 ? '' : 'disabled',
  );
  const updateButtonNextState = (sliderRight, buttonRight) => {
    if (sliderRight < buttonRight) {
      // e.className = "disabled";
      setButtonNextState('disabled');
    } else {
      setButtonNextState('');
    }
  };
  const toggleStateByIndex = (val) => {
    setStateSelectedIndex(val === stateSelectedIndex ? -1 : val);
  };
  let touchStartX;
  return (
    <Wrapper>
      {scroll && (
        <CarouselButton
          className={currentPage > 0 ? '' : 'disabled'}
          onClick={handlePrev}
        >
          <img src={larrow} />
        </CarouselButton>
      )}
      <Carousel scroll={scroll} id="carousel">
        {/* <Slider className={sliderClassName} width={scroll ? ((groupedPatches.length -1) * panelSize) : '780'} offsetLeft={offsetLeft} panelSize={panelSize} page={currentPage} id='slider-container'> */}
        <Slider
          className={sliderClassName}
          width={panelSize}
          height={sizes.height}
          offsetLeft={offsetLeft}
          panelSize={panelSize}
          page={currentPage}
          id="slider-container"
          onTouchStart={(e) => {
            const touchobj = e.changedTouches[0];
            touchStartX = touchobj.pageX;
          }}
          onTouchEnd={(e) => {
            const touchobj = e.changedTouches[0];
            const distX = touchobj.pageX - touchStartX;
            const tolerance = 50;
            if (Math.abs(distX) > tolerance) {
              if (distX < 0) {
                handleNext(e);
              } else if (currentPage > 0) {
                handlePrev(e);
              }
            }
          }}
        >
          {patches.map((patch, patchIndex) => {
            const aspect =
              patch.image.dimensions.width / patch.image.dimensions.height;
            return (
              <Sticker
                key={`sticker-${patchIndex}`}
                className={`sticker_${patchIndex}${
                  patchIndex === stateSelectedIndex && selectable === true
                    ? ' active'
                    : ''
                }`}
                onClick={(dragData) => {
                  onToggleSticker &&
                    selectable === true &&
                    onToggleSticker({
                      type,
                      index: patchIndex,
                      dragData:
                        stateSelectedIndex !== patchIndex ? dragData : null,
                    });
                  trackGtagCustomEvent({
                    category: 'nodfrom2050ecard',
                    action:
                      patchIndex && patchIndex === stateSelectedIndex
                        ? 'sticker_picker_selected'
                        : 'sticker_picker_deselected',
                    label: dragData.imageSharp.childImageSharp.src,
                  });
                  toggleStateByIndex(patchIndex);
                }}
                aspect={aspect}
                sizes={sizes}
                dragData={{
                  imageSharp: patch.imageSharp,
                  width: sizes.width,
                  height: sizes.height,
                }}
                type={type}
              >
                {patch.imageSharp && (
                  <Img
                    style={{ maxHeight: `${sizes.height}px` }}
                    imgStyle={{
                      objectFit: contain ? 'contain' : 'cover',
                    }}
                    fluid={patch.imageSharp.childImageSharp.fluid}
                  />
                )}
                {!patch.imageSharp &&
                  patch.image(
                    <img
                      style={{ maxHeight: `${sizes.height}px` }}
                      src={patch.image.url}
                    />,
                  )}
                <StickerCheckmark src={checkmark} />
              </Sticker>
            );
          })}
        </Slider>
      </Carousel>
      {scroll && (
        <CarouselButton className={buttonNextState} onClick={handleNext}>
          <img src={rarrow} />
        </CarouselButton>
      )}
    </Wrapper>
  );
};

StickerPicker.propTypes = {
  patches: PropTypes.array,
};

StickerPicker.defaultProps = {
  patches: ``,
};

export default StickerPicker;
