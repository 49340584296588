import React from 'react';
import PropTypes from 'prop-types';

import StaticPatch from '../StaticPatch';
import { PatchesWrapper } from './styles';

const Patches = ({ editable, patches, patchRemove }) => (
  <PatchesWrapper>
    {patches &&
      patches.length > 0 &&
      patches.map((patch) => {
        return (
          <StaticPatch
            patchRemove={patchRemove}
            key={`patch-${patch.id}`}
            data={patch}
            editable={editable}
          />
        );
      })}
  </PatchesWrapper>
);

Patches.propTypes = {
  editable: PropTypes.bool,
  patches: PropTypes.array,
};

Patches.defaultProps = {
  editable: true,
  patches: [],
};

export default Patches;
