import styled from 'styled-components';

export const PatchesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .ddcontainer {
    position: absolute !important;
    top: 0;
    /* width:423px; */
    /* height:423px; */
    @media screen and (max-width: 768px) {
      width: 100px;
      height: 100px;
    }
    .ddcontainersource {
      position: absolute;
      top: 0;
      /* width:423px; */
      /* height:423px; */
      @media screen and (max-width: 768px) {
        width: 100px;
        height: 100px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    max-width: 423px;
    max-height: 423px;
    width: calc(100vw - 3rem);
    height: calc(100vw - 3rem);
    object-fit: contain;
  }
`;
