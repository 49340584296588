import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { DragDropContainer } from 'react-drag-drop-container';

import { Wrapper, Page, TargetContainer, TargetMoveable } from './styles';
import { getRandRangeInt } from '../../../utils/math';

const Patch = ({
  data: {
    id,
    image,
    x,
    y,
    size,
    sizes,
    targetx,
    targety,
    killPatch,
    snap,
    scale,
    rotation,
    targetKey,
  },
  patchRemove,
}) => {
  const PATCH_ID = id;
  const PageX = (elem) =>
    window.pageXOffset + elem.getBoundingClientRect().left;
  // const PageY = (elem) => window.pageYOffset + elem.getBoundingClientRect().top
  const PageY = (elem) => elem.getBoundingClientRect().top;

  const [imageX, setImageX] = useState(0);
  const [imageY, setImageY] = useState(0);
  const [randomRotation, setRotation] = useState(0);
  const [randomScale, setScale] = useState(1);
  const [classNamePlaced, setClassNamePlaced] = useState('placed');

  useEffect(() => {
    // Update the document title using the browser API
    document.getElementById(PATCH_ID).classList.remove('placed');
    const page = document.getElementById('design-area');

    const RANDOM_VARIATION = 12;
    if (sizes.mid >= 100) {
      !rotation
        ? setRotation(getRandRangeInt(RANDOM_VARIATION, -RANDOM_VARIATION))
        : setRotation(rotation);
      !scale
        ? setScale(
            getRandRangeInt(100 - RANDOM_VARIATION, 100 + RANDOM_VARIATION) /
              100,
          )
        : setScale(scale);
    }

    // if (window && window.pageYOffset) {
    //   posY += window.pageYOffset;
    // }

    // console.log(window.pageYOffset, page.getBoundingClientRect().top);

    setImageX(PageX(page));
    setImageY(PageY(page));
  }, []);

  const handleImageClick = (e) => {
    console.log('handleImageClick', e);
  };
  const onDragStartPatch = (e) => {
    console.log('onDragStartPatch');
  };
  const onDragEndPatch = (e, currentTarget, x, y) => {
    console.log(
      'onDragEndPatch currentTarget.classList',
      currentTarget.classList,
    );
    if (
      currentTarget.classList.contains('DesignArea') ||
      currentTarget.classList.contains('buttonClear')
    ) {
      patchRemove(e.PATCH_ID);
    }
    // killPatch(2);
  };
  const kill = () => {};
  let isMobileSafari = false;
  if (window && window.navigator && window.navigator.userAgent) {
    if (
      window.navigator.userAgent.match(/iPad/i) ||
      window.navigator.userAgent.match(/iPhone/i)
    )
      isMobileSafari = true;
  }
  // console.log(x, y, targetx, targety, size, ratio);
  const posX = isMobileSafari ? x - imageX : x - imageX; /// ratio+targetx;
  const posY = isMobileSafari ? y - imageY : y - imageY; /// ratio+targety;
  const patch = image;

  return (
    <DragDropContainer
      targetKey={targetKey || 'paper'}
      onDragStart={onDragStartPatch}
      onDragEnd={onDragEndPatch}
      dragData={{
        patch,
        size,
        sizes,
        PATCH_ID,
        snap,
        scale: randomScale,
        rotation: randomRotation,
      }}
    >
      <Wrapper
        className={`StaticPatchWrapper ${classNamePlaced}`}
        rotation={randomRotation}
        scale={randomScale}
        onClick={handleImageClick}
        size={size}
        sizes={sizes}
        left={posX}
        top={posY}
        id={PATCH_ID}
      >
        {sizes.mid < 100 && (
          <img
            src={image.imageSharp.childImageSharp.fluid.src}
            height={sizes.mid}
          />
        )}
        {sizes.mid >= 100 && (
          <img
            src={image.imageSharp.childImageSharp.fluid.src}
            width={sizes.mid}
          />
        )}
      </Wrapper>
    </DragDropContainer>
  );
};

Patch.propTypes = {
  className: PropTypes.string,
};

Patch.defaultProps = {
  className: ``,
};

export default Patch;
