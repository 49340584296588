import styled from 'styled-components';

export const Wrapper = styled.div`
  /* max-width: 865px; */
  width: 60%;
  margin: 1rem auto;
  overflow: auto;
  white-space: nowrap;
  height: 70px;
  display: flex;
  max-width: 780px;
  @media screen and (max-width: 1280px) {
    width: 90%;
  }
  @media screen and (max-width: 880px) {
    width: 100%;
  }
`;

export const Carousel = styled.div`
  width: ${(props) => (props.scroll ? '90%' : '100%')};
  min-width: 423px;
  /* height: 80px; */
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 880px) {
    width: calc(100% - 3rem);
    min-width: 200px;
    margin: 0 1rem;
    border-left: ${(props) => (props.scoll ? '1px solid #CCC' : 0)};
    border-right: ${(props) => (props.scoll ? '1px solid #CCC' : 0)};
  }
`;

export const Slider = styled.div`
  position: relative;
  height: 100px;
  width: ${(props) => props.width}px;
  transition: all ease-out 0.5s;
  /* left: ${(props) => -props.page * 350}px; */
  /* left: ${(props) => -props.page * props.panelSize}px; */
  left: ${(props) =>
    props.offsetLeft ? props.offsetLeft : -props.page * props.panelSize}px;
  margin: 0 auto;
  > div {
    float: left;
  }
`;

export const Slide = styled.div`
  width: 100px;
  height: 80px;
  margin: 10px;
  border-radius: 3px;
  float: left;
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  transition: all 2s;
`;

export const Patch = styled.a`
  cursor: pointer;
  /* width: ${(props) => (props.sizes ? props.sizes.small : '50')}px; */
  height: ${(props) => (props.sizes ? props.sizes.small : '50')}px;
  padding: 0;
  display: inline-block;
  text-align: center;
  margin: 0 ${(props) => (props.sizes ? 3 : 5)}px;
  text-decoration: none;
  > div {
    width: ${(props) =>
      props.sizes ? props.sizes.small * props.aspect : '50'}px;
    margin: 0 auto;
  }
`;

export const DragPatch = styled.div`
  width: ${(props) => (props.sizes ? props.sizes.large : '150')}px;
  height: ${(props) => (props.sizes ? props.sizes.large : '150')}px;
  padding: 0;
  /* margin-left: -${(props) =>
    props.sizes ? props.sizes.large / 4 : '75'}px; */
  /* margin-top: -${(props) =>
    props.sizes ? props.sizes.large / 4 : '75'}px; */
  /* margin-left: -40px;
  margin-top: -40px; */
  margin-left: -${(props) => (props.sizes ? props.sizes.large / 4 : '40')}px;
  margin-top: -${(props) => (props.sizes ? props.sizes.large / 4 : '40')}px;
`;

export const PatchGIF = styled.img`
  width: ${(props) => (props.sizes ? props.sizes.small : '50')}px;
  height: ${(props) => (props.sizes ? props.sizes.small : '50')}px;
  margin: 0;
  padding: 0;
`;

export const DragPatchGIF = styled.img`
  width: ${(props) => (props.sizes ? props.sizes.large : '150')}px;
  height: ${(props) => (props.sizes ? props.sizes.large : '150')}px;
  margin: 0;
  padding: 0;
`;

export const CarouselButton = styled.a`
  /* display: block; */
  margin-top: ${(props) => (props.size ? props.size / 3 : '1')}px;
  /* color: black; */
  cursor: pointer;
  min-width: 11px;
  img {
    filter: brightness(150%) contrast(1%) grayscale(1);
    height: 15px;
  }
  &.disabled {
    opacity: 0.1;
    pointer-events: none;
  }
`;
