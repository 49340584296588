import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RichText } from 'prismic-reactjs';
import { validateEmail, validateRequired } from '../../../utils/validation';
import { sendPageView, trackGtagCustomEvent } from '../../../utils/ga';
import Button from '../../ui/Button';
import ButtonWrapper from '../../ui/ButtonWrapper';

import {
  StyledArticle,
  FormWrap,
  StyledTextField,
} from '../../layoutComponents';
import {
  Container,
  Wrapper,
  RequiredFields,
  FieldWrapper,
  CheckFieldWrapper,
  Label,
  Input,
  Select,
  PreviewImageWrapper,
  CardPreviewText,
  BodyWrap,
} from './styles';

const EmailForm = ({
  data: { fields, primary },
  setIsValid,
  isValid,
  salutation,
  setSalutation,
  senderFirstName,
  setSenderFirstName,
  senderLastName,
  setSenderLastName,
  toEmail,
  setToEmail,
  country,
  setCountry,
  optOffers,
  setOptOffers,
  imgData,
  setActiveStep,
  locale,
}) => {
  useEffect(() => {
    sendPageView('nodfrom2050ecard_step_3', '', 'Communication');
    if (locale === 'tha-th')
      setSalutation(String(data.primary.salutation_options.split('\n')[0]));
  }, []);

  // TODO: validate the values in the form
  const checkValid = () => {
    if (!validateEmail(toEmail)) return setIsValid(false);
    if (!validateRequired(senderFirstName)) return setIsValid(false);
    setIsValid(true);
  };

  const handleSend = (e) => {
    e.preventDefault();
    setActiveStep(4);
  };

  // TODO: add error state to the form fields
  return (
    <Container>
      <PreviewImageWrapper
        onClick={() => {
          setActiveStep(1);
        }}
      >
        <img src={imgData} />
      </PreviewImageWrapper>
      <BodyWrap>
        <FormWrap>
          <StyledArticle>{RichText.render(primary.share_title)}</StyledArticle>
          <RequiredFields>
            <strong>*
{primary.required_fields}
</strong>
          </RequiredFields>
          <>
            <FieldWrapper>
              <StyledTextField
                label={null}
                placeholder={`*${primary.first_name}`}
                value={senderFirstName}
                onChange={(e) =>
                  setSenderFirstName(
                    e.target.value.replace(/[\|&;\$%@"<>\(\)\+,]/g, ''),
                  )
                }
              />
            </FieldWrapper>
          </>
          <FieldWrapper>
            <StyledTextField
              label={null}
              placeholder={`*${primary.to_email_address}`}
              value={toEmail}
              onChange={(e) =>
                setToEmail(e.target.value.replace(/[\|&;\$%"<>\(\)\+,]/g, ''))
              }
              onBlur={(e) => checkValid()}
            />
          </FieldWrapper>
          <CardPreviewText>{primary.preview_text}</CardPreviewText>
          <ButtonWrapper>
            <Button
              handleClick={(e) => {
                e.preventDefault();
                setActiveStep(1);
              }}
              pageEventAction="communication_button_back"
            >
              {primary.button_label_back}
            </Button>
            <Button
              disabled={!isValid}
              handleClick={handleSend}
              pageEventAction="communication_button_send"
            >
              {primary.send}
            </Button>
          </ButtonWrapper>
        </FormWrap>
      </BodyWrap>
    </Container>
  );
};

const StyledEmailForm = styled(EmailForm)`
  color: #fffaff;
  background: var(--primary-color);
  padding: 1rem 0;
`;

EmailForm.propTypes = {
  data: PropTypes.object.isRequired,
  setIsValid: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  toEmail: PropTypes.string.isRequired,
  setToEmail: PropTypes.func.isRequired,
};

export default StyledEmailForm;
