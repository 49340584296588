import styled from 'styled-components';
import { Tooltip } from '@rmwc/tooltip';
import '@rmwc/tooltip/styles';

export const ShareWrapper = styled.div``;

export const ShareList = styled.ul`
  margin-top: Calc(var(--margin-tb));
  margin-left: 0;
  list-style: none;
  font-size: 1.375rem;
  li {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
`;
export const IconList = styled.div`
  display: flex;
  margin: 0.5rem 0 0 0.5rem;

  /* margin-top: Calc(var(--margin-tb) / 2); */
`;
export const IconContainer = styled.div`
  cursor: pointer;
  margin: 0 0.5rem;
`;
export const Icon = styled(Tooltip)`
  &:hover {
    opacity: 0.5;
  }
`;
