import styled from 'styled-components';

import { Button as RWMCButton } from '@rmwc/button';
import '@rmwc/button/styles';

export const StyledButton = styled(RWMCButton)`
  /* cursor: pointer;
  color: #ffffff;
  background: var(--secondary-color);
  padding: ${(props) => (props.small === true ? '4px 10px' : '0.5rem 1rem')};
  margin-bottom: 1rem; */
  border: 1px solid #0c56c4;
  /* margin: 0px; */
  /* transition: all ease-out 0.15s;
  z-index: 2;
  font-size: ${(props) => (props.small === true ? '0.7' : '0.875')}rem;
  line-height: ${(props) => (props.small === true ? '0.8' : '1')}rem;
  letter-spacing: Calc(-1em * 0.001 * -89);
  font-weight: 500; */
  /* pointer-events: all; */
  text-transform: uppercase;
  /* border-radius: 4px; */
  &:hover {
    color: var(--secondary-color);
    border-color: var(--secondary-color);
    background-color: transparent;
  }
  /* &:disabled {
    border-color: #aaa;
    color: #aaa;
    background: #efefed;
  } */
`;
