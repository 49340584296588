import styled from 'styled-components';

import { TextField } from '@rmwc/textfield';
import '@rmwc/textfield/styles';
import dialogBG from '../../images/dialogBackground.svg';

export const StyledArticle = styled.article`
  position: relative;
  height: 100%;
  display: block;
  z-index: 1;
  .buttonWrapper {
    bottom: 0;
    position: absolute;
    width: 100%;
    justify-content: center;
    @media screen and (max-width: 1024px) {
      margin-top: 0;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      button {
        margin-top: 1rem;
      }
    }
    @media screen and (max-width: 768px) {
      position: static;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: wrap;
      margin-top: 2rem;
      button {
        margin: 0.5rem 0.5rem;
        min-width: 130px;
        &:first-child {
        }
        &:last-child {
          margin: 0.5rem auto;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: var(--margin-tb);
  }
  @media screen and (max-width: 420px) {
    margin-top: 5rem;
    margin: 5rem 0;
  }
`;

export const ShadowWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  color: #ffffff;
  display: none;
  z-index: 10;
  &.active {
    display: inline-block;
  }
  p {
    text-shadow: 0.5px 0.5px #ad5e50;
    font-size: 1.5rem;
    line-height: 1em;
    @media screen and (max-width: 1024px) {
      font-size: 0.875rem;
      line-height: 0.9375rem;
    }
  }

  &.dialog_dpw {
    .dialogWrapper {
      @media screen and (max-width: 1024px) {
        width: 512px;
      }
      @media screen and (max-width: 768px) {
      }
    }
    article {
      margin-top: 74px;
      .action_copy {
        margin-top: 180px;
      }
      @media screen and (max-width: 1024px) {
        margin-top: 37px;
        .action_copy {
          margin-top: 30px;
        }
      }
    }
  }
  &.dialog_dmw {
    .dialogWrapper {
      @media screen and (max-width: 1024px) {
        height: 620px;
      }
      @media screen and (max-width:768px){
        height: auto;
      }
    .buttonWrapper {
      position: static;
      margin-top: Calc(var(--margin-tb) / 2);
    }
    .dynamicInputWrapper {
      max-width: 365px;
      margin: 0 auto;
      p {
        font-style: italic;
        font-weight: 500;
      }
      label {
        margin-top: 0.625rem;
        margin-bottom: 0.625rem;
      }
    }
  }

  @media screen and (max-height: 445px) {
    overflow: auto;
    .dialogWrapper {
      transform: translate(-50%, 0);
      min-height: 600px;
      height: 600px;
      max-height: 600px;
      top: 0;

    }
  }
`;
export const ProgressIndicator = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  label {
    color: var(--mdc-theme-primary);
    text-align: center;
    font-size: 1rem;
    line-height: 1.15rem;
  }
`;
export const PhotoWrapper = styled.div`
  position: relative;
  width: ${(props) => props.width}px;
  min-width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  margin-right: Calc(var(--margin-lr) * 2);
  overflow: hidden;
  background: white;
  .rmwc-circular-progress {
  }
  @media screen and (max-width: 768px) {
    border-radius: 4px;
    overflow: hidden;
    margin-right: 15px;
  }
`;
export const VideoWrapper = styled.video`
  position: absolute;
  object-fit: cover;
`;
export const CanvasWrapper = styled.div`
  position: absolute;
  &:hover,
  &.softHide {
    canvas {
      opacity: 0.5;
    }
  }
  &.hide {
    display: none;
  }
`;
export const ShadowCurtain = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  cursor: pointer;
  z-index: 11;
  background: rgba(0, 0, 0, 0.35);

  @media screen and (max-height: 445px) {
  }
`;
export const DialogWrapper = styled.div`
  pointer-events: none;
  width: 846px;
  height: 808px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border: none;
  color: #000;
  pointer-events: all;
  z-index: 12;
  cursor: default;
  position: relative;
  display: inline-block;
  background: no-repeat center center;
  background-size: 100% 100%;
  background-image: URL(${dialogBG});
  color: #fff;
  h2 {
    color: #c8fff4;
    mix-blend-mode: normal;
    display: inline-block;
    margin: 0;
    @media screen and (max-width: 1024px) {
    }
    @media screen and (max-width: 768px) {
      font-size: 1.5625rem;
      line-height: 1.75rem;
      max-width: 53vw;
    }
  }
  .logo {
    width: 121px;
    height: 58px;
    display: inline-block;
    margin-top: 14px;
    margin-left: 27px;
    margin-right: 16px;
  }
  @media screen and (max-width: 1024px) {
    width: 450px;
    height: 500px;
    background-image: none;
    max-height: 100vh;
    overflow: auto;
    .logo {
      width: 82px;
      height: 40px;
      margin: 0;
    }
  }
  @media screen and (max-width: 768px) {
    width: Calc(100vw - 1.5rem) !important;
    height: auto;

    .logo {
      position: absolute !important;
    }
  }
`;
export const DialogWrapperFill = styled.div`
  margin: var(--margin);
  padding: var(--margin);
  background: linear-gradient(#fda59f, #fea191, #d3b5cf);
  height: Calc(100% - var(--margin-tb) * 2);
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    margin: 0;
    padding: 1.0625rem 1.8125rem;
    border-radius: 4px;
    height: 100%;
  }
`;
export const DialogClose = styled.div`
  cursor: pointer;
  position: absolute;
  width: 28px;
  height: 28px;
  right: 0;
  top: 0;
  img {
    position: absolute;
    top: 20px;
    right: 20px;
    display: block;
    transition: all 0.125s;
  }
  &:hover {
    .idle {
      opacity: 0;
    }
    .over {
      opacity: 1;
    }
  }
  .idle {
    opacity: 1;
  }
  .over {
    opacity: 0;
  }
  @media screen and (max-width: 1024px) {
    img {
      width: 14px;
      height: 14px;
    }
  }
`;
export const PlinthImg = styled.img`
  display: block;
  position: absolute;
  top: 300px;
  right: 90px;
  width: 150px;
  height: 184px;
  opacity: 0.3;
  mix-blend-mode: multiply;
  @media screen and (max-width: 768px) {
    width: 62px;
    height: 77px;
    right: var(--margin-lr);
    top: 40%;
    &.plinthBG_dialog_dmw {
      top: 25%;
    }
  }
`;
export const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  p {
    text-align: right;
  }
  @media screen and (max-width: 1024px) {
    p {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
`;
export const StyledHeaderTitle = styled.div`
  width: Calc(100% - 121px - 27px - 16px);
  @media screen and (max-width: 1024px) {
    width: auto;
  }
  @media screen and (max-width: 768px) {
    position: static;
    margin-top: 0;
    h2 {
      margin-left: 100px;
      margin-bottom: 17px;
    }
  }
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
`;
export const UIWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
  &.rowFields {
    margin-top: var(--margin-tb);
  }
`;
export const RowWrapperCameraButton = styled(RowWrapper)`
  justify-content: flex-start;
  button {
    min-width: 20px;
    width: 50px;
    margin-right: 1rem;
  }
`;
export const FieldWrapper = styled(RowWrapper)`
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: Calc(var(--margin-tb) * 0.5);

  .mdc-form-field {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    label {
      margin-right: 0;
      margin-left: 0;
      padding-left: 0;
    }
  }
  label {
    margin-top: 0px;
    font-weight: 400;
    margin-right: var(--margin-lr);
  }
  &.tuneSlider {
    margin-top: -1.25rem;
    label {
      margin-top: -1.25rem;
    }
  }
  @media screen and (max-width: 1024px) {
    label {
      margin-top: 0.5rem;
    }
    &.tuneSlider label {
      margin-top: -0.5rem;
    }
  }
`;
