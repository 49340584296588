export const sendPageView = (page, page_location, page_title) => {
  if (typeof window !== 'undefined' && window && window.gtag) {
    console.log(
      '*******sendPageView**********',
      page,
      page_title,
      page_location,
    );
    window.gtag('event', 'page_view', {
      page_path: `/${page}`,
      page_title,
      page_location,
    });
  }
};

export const sendGAPageView = (page) => {
  if (typeof window !== 'undefined' && window && window.ga) {
    window.ga('send', {
      hitType: 'pageview',
      title: page,
      page: window.location.pathname,
    });
  }
};

/**
 *
 * @param {string} category
 * @param {string} action
 * @param {string} label
 * @param {number} value
 * @param {boolean} nonInteraction
 * @param {enum} transport "beacon" | "xhr" | "image"
 * @param {func} hitCallback
 * @param {number} callbackTimeout
 */
export const trackCustomEvent = ({
  category,
  action,
  label,
  value,
  nonInteraction,
  transport,
  hitCallback,
  callbackTimeout,
}) => {
  if (typeof window !== 'undefined' && window && window.ga) {
    // console.log('tracking custom event');
    const trackingEventOptions = {
      event_category: category,
      event_label: label,
    };
    // window.ga('send', 'event', category, action, label);

    window.gtag('event', action, trackingEventOptions);
  }
};

/**
 *
 * @param {string} category
 * @param {string} action
 * @param {string} label
 * @param {number} value
 * @param {boolean} nonInteraction
 * @param {enum} transport "beacon" | "xhr" | "image"
 * @param {func} hitCallback
 * @param {number} callbackTimeout
 */
export const trackGtagCustomEvent = ({
  category,
  action,
  label,
  value,
  nonInteraction,
  transport,
  hitCallback,
  callbackTimeout,
}) => {
  if (typeof window !== 'undefined' && window && window.gtag) {
    const trackingEventOptions = {
      event_category: category,
      event_label: label || '',
    };
    console.log(trackingEventOptions, action);
    window.gtag('event', action, trackingEventOptions);
  }
};
