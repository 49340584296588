import { useDrop } from 'react-dnd';
import React, { useEffect } from 'react';

const DragNDropTarget = ({
  id,
  children,
  className,
  onDrop,
  accept = ['default'],
}) => {
  const [{ isOver, canDrop, getDropResult, getItem }, drop] = useDrop(
    () => ({
      accept,
      drop: onDrop,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        getDropResult: monitor.getDropResult(),
        getItem: monitor.getItem(),
      }),
    }),
    [accept, onDrop],
  );
  const isActive = canDrop && isOver;

  return (
    <div id={id} className={className} ref={drop} role="DragNDropTarget">
      {accept}
      {children}
    </div>
  );
};
export default DragNDropTarget;
