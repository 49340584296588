import styled, { keyframes } from 'styled-components';
import Button from '../../ui/Button';
import carouselSlideBG from '../../../images/plinthLowOpacity.png';
import endscreenConfirmation from '../../../images/endscreenConfirmation.png';

const fadeIn = keyframes`
from{
  opacity:0;
}
to{
  opacity:1;
}`;

export const Container = styled.div`
  width: Calc(100vw - var(--margin-lr) * 2);
  height: auto;
  background: URL(${carouselSlideBG}) no-repeat;
  background-position: 60% 8%;
  animation: ${fadeIn} 1s ease-out;

  @media screen and (max-width: 768px) {
    width: 100vw;
  }
`;
export const PreviewImageWrapper = styled.div`
  width: Calc(50vw - var(--margin-lr));
  height: Calc(100vh - var(--margin-tb) * 2);
  overflow: hidden;
  position: fixed;
  cursor: pointer;
  pointer-events: all;
  animation: ${fadeIn} 1s ease-out;
  img {
    object-fit: cover;
    display: block;
    width: Calc(50vw - var(--margin-lr));
    height: Calc(100vh - var(--margin-tb) * 2);
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: 50vh;
    position: static;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;
export const BodyWrap = styled.div`
  width: Calc(50vw - 1rem);
  margin: 3rem 0 0 50%;
  min-height: 100vh;
  overflow: auto;
  background: URL(${endscreenConfirmation}) no-repeat 100% 68%;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 3rem 0 0 0;

    background-size: 213px 213px !important;
    background-position: Calc(100vw - 106px) 47%;
  }
`;

export const FieldWrapper = styled.fieldset`
  border: 0;
  width: 100%;
  @media screen and (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
`;

export const CheckFieldWrapper = styled.fieldset`
  border: 0;
  width: 100%;
  label {
    display: block;
    padding-left: 15px;
    text-indent: -15px;
  }
  input {
    width: 15px;
    height: 15px;
    padding: 0;
    margin: 0 10px 0 0;
    vertical-align: middle;
    position: relative;
    top: -1px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 0.75rem;
  }
`;

export const RequiredFields = styled.p`
  text-align: left;
  font-size: 0.8em;
  margin: 0.5rem 0;
`;

export const Label = styled.label``;

export const Input = styled.input`
  border: 1px solid #e9e8e4;
  padding: 6px 12px;
  width: 100%;
`;

export const TextArea = styled.textarea`
  border: 1px solid #e9e8e4;
  padding: 6px 12px;
  width: 100%;
`;

export const Select = styled.select`
  border: 1px solid #e9e8e4;
  width: 100%;
  padding: 6px 12px;
  background: none;
  border-radius: 0;
  option {
    font-weight: 300;
  }
`;

export const CardPreviewText = styled.p`
  margin-top: 3rem;
  font-size: 1rem;
  max-width: 296px;
  line-height: 1.5rem;
`;

export const SendButtonWrap = styled.div`
  margin-bottom: 50px;
`;
