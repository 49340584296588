import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { DragDropContainer } from 'react-drag-drop-container';
import { v4 as uuidv4 } from 'uuid';

import {
  Wrapper,
  Patch,
  DragPatch,
  Carousel,
  Slider,
  CarouselButton,
  PatchGIF,
  DragPatchGIF,
} from './styles.js';
import larrow from '../../../images/larrow.png';
import rarrow from '../../../images/rarrow.png';

const PatchPicker = ({
  snap,
  patches,
  size,
  sizes,
  numPatchesPerPanel,
  scroll,
  type,
  targetKey,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [offsetLeft, setOffsetLeft] = useState(0);

  // const numPatches = 7; //Math.floor(423 / 60);
  const groupedPatches = [];

  const sliderClassName = uuidv4();

  let i;
  let j = 10;
  for (i = 0, j = patches.length; i < j; i += numPatchesPerPanel) {
    groupedPatches.push(patches.slice(i, i + numPatchesPerPanel));
  }

  const handleNext = (e) => {
    e.preventDefault();

    const carousel = document.getElementById('carousel');
    const slider = document.getElementById('slider-container');
    const patchesWidth =
      sizes.small > 40
        ? patches.length * Math.round(sizes.small * 1.1)
        : patches.length * Math.round(sizes.small * 1.25);
    const maxLeft = -(patchesWidth - carousel.offsetWidth);
    setOffsetLeft(Math.max(-(currentPage + 1) * panelSize, maxLeft));

    setCurrentPage(currentPage + 1);

    // console.log(e.pageX);
    // updateButtonNextState((document.getElementsByClassName(sliderClassName)[0].getBoundingClientRect().right - panelSize), e.pageX);
    updateButtonNextState(-(currentPage + 1) * panelSize, maxLeft);
  };

  const handlePrev = (e) => {
    e.preventDefault();

    setOffsetLeft(Math.min(-(currentPage - 1) * panelSize, 0));

    setCurrentPage(currentPage - 1);
    updateButtonNextState(100, 0);
  };

  const panelSize = (sizes.small + sizes.small * 0.3) * numPatchesPerPanel;

  const [buttonNextState, setButtonNextState] = useState(
    currentPage < groupedPatches.length - 1 ? '' : 'disabled',
  );
  const updateButtonNextState = (sliderRight, buttonRight) => {
    if (sliderRight < buttonRight) {
      // e.className = "disabled";
      setButtonNextState('disabled');
    } else {
      setButtonNextState('');
    }
  };
  return (
    <Wrapper>
      {scroll && (
        <CarouselButton
          size={sizes.small}
          className={currentPage > 0 ? '' : 'disabled'}
          onClick={handlePrev}
        >
          <img src={larrow} />
        </CarouselButton>
      )}

      <Carousel scroll={scroll} id="carousel">
        {/* <Slider className={sliderClassName} width={scroll ? ((groupedPatches.length -1) * panelSize) : '780'} offsetLeft={offsetLeft} panelSize={panelSize} page={currentPage} id='slider-container'> */}
        <Slider
          className={sliderClassName}
          width={
            scroll ? patches.length * Math.round(sizes.small * 1.25) : '780'
          }
          offsetLeft={offsetLeft}
          panelSize={panelSize}
          page={currentPage}
          id="slider-container"
        >
          {groupedPatches.map((group, idx) => {
            return (
              <div key={`group-${idx}-${group.length}`}>
                {group.map((patch) => {
                  const customElem = (
                    <DragPatch sizes={sizes}>
                      {/* { !patch.gif && ( */}
                      <Img
                        style={{ maxHeight: `${sizes.large}px` }}
                        imgStyle={{ objectFit: 'contain' }}
                        fluid={patch.imageSharp.childImageSharp.fluid}
                      />
                      {/* ) || (
                            <DragPatchGIF src={patch.gif.url} sizes={sizes} />
                          )} */}
                    </DragPatch>
                  );
                  const aspect =
                    patch.image.dimensions.width /
                    patch.image.dimensions.height;
                  return (
                    <DragDropContainer
                      customDragElement={customElem}
                      key={patch.image.url}
                      targetKey={targetKey || 'paper'}
                      dragData={{ patch, sizes, snap, type, targetKey }}
                      onDragStart={() => {
                        console.log('onDragStart targetKey', targetKey);
                      }}
                    >
                      <Patch aspect={aspect} sizes={sizes}>
                        {/* { !patch.gif && ( */}
                        <Img
                          style={{ maxHeight: `${sizes.small}px` }}
                          imgStyle={{ objectFit: 'contain' }}
                          fluid={patch.imageSharp.childImageSharp.fluid}
                        />
                        {/* ) || (
                              <PatchGIF src={patch.gif.url} sizes={sizes} />
                            )} */}
                      </Patch>
                    </DragDropContainer>
                  );
                })}
              </div>
            );
          })}
        </Slider>
      </Carousel>
      {scroll && (
        <CarouselButton
          size={sizes.small}
          className={buttonNextState}
          onClick={handleNext}
        >
          <img src={rarrow} />
        </CarouselButton>
      )}
    </Wrapper>
  );
};

PatchPicker.propTypes = {
  patches: PropTypes.array,
};

PatchPicker.defaultProps = {
  patches: ``,
};

export default PatchPicker;
