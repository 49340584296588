import styled from 'styled-components';
import { DialogTitle } from '@rmwc/dialog';

import { Select } from '@rmwc/select';
import DropTarget from '../DropTarget';
import DraggableItem from '../DraggableItem';

import postcardBackBackground from '../../../images/postcardBackBackground.png';
import plinthDesignArea from '../../../images/plinthDesignArea.png';

import '@rmwc/select/styles';

export const WrapperRow = styled.div`
  display: flex;
  justify-content: space-between;
  label {
    margin-right: 2rem;
  }
  @media screen and (max-width: 1420px) {
    flex-direction: column;
    align-items: flex-start;
    button {
      margin-top: var(--margin-tb);
    }
    label {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 1024px) {
    align-items: flex-end;
  }
  @media screen and (max-width: 768px) {
  }
`;
export const UIRow = styled(WrapperRow)`
  &.fontFamilyColorInputGroup {
    width: 345px;
    margin-top: Calc(var(--margin-tb) / 2);
    justify-content: flex-start;
  }
  &.fontColorInputGroup {
    align-items: center;
    label {
      margin-right: var(--margin-lr);
    }
  }
  &.fontSliderGroup {
    width: 345px;
    margin-top: Calc(var(--margin-tb) / 2);
    align-items: center;
    justify-content: flex-start;
    .sizeSlider {
      width: 50%;
    }
    label {
      margin-right: Calc(var(--margin-lr) / 2);
      min-width: 75px;
    }
  }
  .fontSelector {
    margin-right: 4.3125rem;
  }
  label {
    font-size: 1.375rem;
  }
  @media screen and (max-width: 1024px) {
    align-items: flex-start;
    &.fontFamilyColorInputGroup {
      width: 100%;
    }
    &.fontColorInputGroup {
      margin-top: Calc(var(--margin-tb) / 2);
      label {
        margin-bottom: 0.75rem;
      }
    }
    &.fontColorInputGroup,
    &.fontSliderGroup {
      align-items: flex-start;
      width: 100%;
    }
  }
`;
export const Container = styled.div`
  width: Calc(100vw - var(--margin-lr) * 2);
  /* height: Calc(100vh - var(--margin-tb) * 2); */
  height: auto;
  min-height: 800px;
  /* min-height: Calc(100vh - 150px); */
  /* transition: all ease-out 1s 1s;
  background: var(--primary-color)
    URL(${(props) =>
    props.siteBG ? props.siteBG.childImageSharp.fixed.src : ''})
    top left no-repeat;
  background-attachment: fixed; */
  &.page_1 {
    .styledArticle {
      h1 {
        margin-top: 4rem;
      }
    }
  }
  &.page_2 {
    overflow: hidden;

    z-index: 1;
    top: 0;
  }
  .designArea {
  }
  &.activeStep_3 {
    opacity: 1;
    pointer-events: none;
    position: absolute;
    .designArea {
      transition: all ease-out 1s 1s;
      opacity: 0;
    }
    .editArea {
      opacity: 0;
    }
  }
  .buttonWrapper {
    justify-content: flex-end;
  }
  @media screen and (max-width: 1280px) {
    .styledArticle {
      h1 {
        font-size: 4.375rem;
        margin-top: 4rem;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    &.page_2 {
      position: absolute;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    min-height: 50vh;
    &.page_1 {
      .styledArticle {
        h1 {
          font-size: 3.75rem;
        }
      }
    }
  }
  @media screen and (max-width: 512px) {
    .buttonWrapper {
      flex-wrap: wrap;
      button {
        margin-bottom: 1rem;
        margin-left: var(--margin-lr);
        margin-right: 0;
      }
    }
  }
`;

export const CarouselSlide = styled.div``;

export const EditArea = styled(DropTarget)`
  width: Calc(50vw - 1rem);
  margin: 3rem 0 0 50%;
  /* max-height: Calc(100vh - 3rem); */
  min-height: 50vh;
  overflow: auto;
  .mdc-select {
    width: 128px;
  }
  &.page_2 {
    position: fixed;
    top: 0;
    width: 50vw;
    left: 50vw;
    height: 100vh;
    margin: 0;
    padding: 3rem;
    pointerevents: none;
    /* display: none; */
  }
  @media screen and (max-width: 1024px) {
    &.page_2 {
      width: 100vw;
      left: 0;
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.25);
      text-align: center;
      h1 {
        width: 100%;
      }
      form {
        padding: 0;
      }
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    margin: 3rem 0 0;
    margin-top: 32vh;
    @media screen and (max-height: 768px) {
      margin-top: 245px;
    }
    h1 {
      font-size: 2.5rem;
      letter-spacing: 0;
    }
    &.page_1 {
      margin-top: 50vh;
    }
  }
  @media screen and (max-width: 380px) {
    &.page_2 {
      h1 {
        font-size: 3rem;
      }
    }
  }
`;
export const DeleteStickerArea = styled.div`
  background: rgba(0, 0, 0, 0.85);
`;
export const WrapperStamp = styled.div`
  display: block;
  width: 200px;
  height: 250px;
  border: 1px solid #f0f0f0;
`;
export const PostcardText = styled.div`
  font-family: \"${(props) => (props.fontFamily ? props.fontFamily : 'sans')}\";
  color: ${(props) => (props.color ? props.color : '#000000')};
  font-size: ${(props) =>
    props.fontSize ? `${props.fontSize / 16}rem` : '3rem'};
  white-space: nowrap;
  line-height: ${(props) =>
    props.fontSize ? `${props.fontSize / 16}rem` : '3rem'};
  text-align: center;
`;
export const CardMessageFront = styled(PostcardText)``;
export const CardSubtitleFront = styled(PostcardText)``;

export const CardMessageBack = styled.div`
  font-family: 'Dads_recipe';
  color: #cf6679;
  width: Calc(50% - 2rem);
  margin: 0 1rem;
  font-size: 2.14275rem;
  line-height: 1.625rem;
  p {
  }
  position: absolute;
`;
export const CardAddressBack = styled(CardMessageBack)`
  left: 55%;
  top: 62%;
  margin: 0;
  width: Calc(50% - 1rem);
  transform: translateY(-50%) rotate(3deg);
  p {
    font-size: 2.5rem;
    line-height: 2rem;
  }
`;
export const CardSubtitleBack = styled.div`
  font-size: 2.14275rem;
  margin-top: 0.5rem;
  text-align: center;
  font-weight: 500;
  padding-right: 2rem;
`;
export const StampWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 18px;
  width: 95px;
  height: 114px;
  border: 1px solid rgba(0, 0, 0, 0);

  img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
    display: block;
  }
`;
export const StampFrameWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 18px;
  width: 95px;
  height: 114px;
  border: 1px solid rgba(0, 0, 0, 0);
  img {
    object-fit: 100% 100%;
    object-position: center center;
    width: 100%;
    height: 100%;
    display: block;
  }
`;
export const TypeCard = styled(DropTarget)`
  display: block;
  border: 1px solid #999999;
  position: absolute;
  background: #fff;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.35);
  overflow: hidden;
`;
export const CardFront = styled(TypeCard)`
  border: 1px solid rgba(0, 0, 0, 0);
  &.active_STICKER,
  &.active_DROPPED_STICKER {
    border-color: green;
  }
`;
export const CardBack = styled.div`
  display: block;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.35);
  background: #fff;
  position: relative;
  background: URL(${postcardBackBackground}) center center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0);
  @media screen and (max-width: 768px) {
  }
`;
export const StickerImage = styled.div`
  position: absolute;
  left: ${(props) => (props.position === 'left' ? 0 : 'auto')};
  right: ${(props) => (props.position === 'right' ? 0 : 'auto')};
  top: ${(props) => (props.position === 'left' ? '50%' : '30%')};
  width: ${(props) => props.w}px;
  height: ${(props) => props.h}px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const RequiredFields = styled.p`
  text-align: right;
  font-size: 0.8em;
  margin: 0.5rem 0;
`;

export const FieldWrapper = styled.fieldset`
  border: 0;
  width: 100%;
  margin-top: 2.325rem;
  margin-bottom: 0.75rem;

  h2 {
    margin-top: 0;
    font-style: italic;
    font-size: 1.5625rem;
    color: #210000;
    mix-blend-mode: normal;
    font-family: 'Alegreya Sans';
    font-weight: 500;
    margin-bottom: 0;
    @media screen and (max-width: 768px) {
    }
  }
  p {
    margin-top: 0.325rem;
    font-size: 1.375rem;
  }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 1rem;
    }
    p {
      font-size: 0.875rem;
      line-height: 1.3125rem;
    }
  }
`;

export const Label = styled.label`
  font-size: 0.9em;
`;

export const StyledSelect = styled(Select)`
  width: 425px;
`;

export const Input = styled.input`
  border: 1px solid #e9e8e4;
  padding: 6px 12px;
  width: 100%;
`;

export const StyledTextArea = styled.textarea`
  border: 1px solid #e9e8e4;
  padding: 6px 12px;
  width: 425px;
  height: 100%;
  min-height: 100px;
  font-size: 16px;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #000;
`;

export const CharacterCount = styled.span`
  font-size: 0.8rem;
  width: 100%;
  text-align: right;
  display: block;
`;

export const DesignArea = styled(DropTarget)`
  position: fixed;
  top: var(--margin-tb);
  width: Calc(50vw - var(--margin-lr));
  height: Calc(100vh - var(--margin-tb) * 2);
  margin: 0;
  overflow: hidden;
  background: ${(props) =>
    props.wallpaper
      ? `url("${props.wallpaper}") repeat top left`
      : `url("${plinthDesignArea}") no-repeat 72% 49% rgba(235,213,213, 0.65)`};
  &.no-margin {
    margin: 0 !important;
  }
  &.no-shadow {
    .has-shadow {
      box-shadow: none !important;
    }
  }

  border: 1px solid rgba(0, 0, 0, 0);
  &.active_WALLPAPER {
    border-color: green;
  }

  &.active_STAMP,
  &.active_STAMP_FRAME {
    .wrapperStampFrame {
      border-color: green;
    }
  }
  &.active_FRONT_IMAGE {
    .cardFront {
      border-color: green;
    }
  }

  .cardBack {
    max-width: 600px;
    max-height: 400px;
    left: 50%;
  }
  .cardFront {
    max-width: 400px;
    max-height: 600px;
    left: Calc(100% + 200px - 4vw);
  }
  .cardFront,
  .cardBack {
    width: 600px;
    height: 600px;
    top: 50%;
    transition: left 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
    transform: translate(-50%, -50%);
  }
  &.page_1 {
    .cardBack {
      left: Calc(-300px + 4vw);
    }
    .cardFront {
      left: 50%;
    }
  }

  &.page_2 {
    width: 916px;
    height: 891px;
    max-width: 916px;
    max-height: 891px;
    .cardFront,
    .cardBack {
      left: 50%;
      transition: none;
      transform: translate(-50%, -50%) scale(0.75);
    }
    .cardFront {
      top: Calc(225px + var(--margin-tb));
    }
    .cardBack {
      top: Calc(450px + 150px + var(--margin-tb) * 2);
    }
  }
  @media screen and (max-width: 1600px) {
    &.page_0 {
      .cardFront {
        left: Calc(100% + 200px - 10px);
      }
    }
  }
  @media screen and (max-width: 1300px) {
    .cardFront,
    .cardBack {
      transform: translate(-50%, -50%) scale(0.75);
    }
  }
  @media screen and (max-width: 1024px) {
    height: Calc(100vh - var(--margin-tb) - 175px);
    max-height: 100vh;
    min-height: 250px;

    .cardFront,
    .cardBack {
      transform: translate(-50%, -50%) scale(0.5);
    }
    &.page_1 {
      height: 50vh;
      min-height: 300px;
    }
    &.page_2 {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: 32vh;
    min-height: 245px;
    top: 0;
    z-index: 2;
    transition: height 0.5s ease-out;
    max-height: 100vh;
    background-color: rgba(235, 213, 213, 1);
    .droptarget {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
`;
export const CardImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    margin: 0;
    padding: 0;
    /* box-shadow: 2px 2px 2px 0px rgba(0,0,0,0.13); */
  }
`;
export const DroppedItemLayer = styled.div`
  position: absolute;
  display: inline-block;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
export const Sticker = styled(DraggableItem)`
  position: absolute;
  z-index: 1;
  background: transparent;
  img {
    pointer-events: none; /* fix for firefox drag and drop */
  }
`;
export const StyledDialogTitle = styled(DialogTitle)`
  mix-blend-mode: normal;
`;
