import styled from 'styled-components';

import DraggableItem from './DraggableItem';
import DragNDropTarget from './DragNDropTarget';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const DraggableDuplicates = styled.div`
  position: absolute;
  display: inline-block;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
export const TypeArea = styled.div`
  display: flex;
  flex-direction: column;
  width: Calc(50vw - 2rem);
  height: Calc(100vh - 2rem);
  border: 1px solid #f0f0f0;
  position: relative;
`;
export const DesignArea = styled(TypeArea)`
  justify-content: space-evenly;
  align-items: center;
`;
export const EditArea = styled(DragNDropTarget)`
  display: flex;
  flex-direction: column;
  width: Calc(50vw - 2rem);
  height: Calc(100vh - 2rem);
  border: 1px solid #f0f0f0;
  position: relative;
`;
export const TypeCard = styled(DragNDropTarget)`
  width: 30vh;
  height: 40vh;
  display: block;
  border: 1px solid #f0f0f0;
  position: relative;
`;
export const CardBack = styled(TypeCard)`
  width: 40vh;
  height: 30vh;
`;
export const CardFront = styled(TypeCard)``;

export const Sprite = styled(DraggableItem)`
  width: 100px;
  height: 100px;
  display: flex;
  border: 1px solid #f0f0f0;
  justify-content: center;
  align-items: center;
  position: absolute;
  &:nth-child(1) {
    left: 100px;
  }
`;
