import styled from 'styled-components';

import { Chip, ChipSet } from '@rmwc/chip';

export const ChipSetWrapper = styled.div`
  @media screen and (max-width: 1024px) {
    .mdc-chip-set {
      .mdc-chip {
        font-size: 0.875rem;
      }
    }
  }
`;
