import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import { RichText } from 'prismic-reactjs';
import { v4 as uuidv4 } from 'uuid';
import { sendPageView, trackGtagCustomEvent } from '../../../utils/ga';
import Button from '../../ui/Button';
import ButtonWrapper from '../../ui/ButtonWrapper';

import iconDownload from '../../../images/icons/Download.png';
import iconFacebook from '../../../images/icons/Facebook.png';
import iconMail from '../../../images/icons/Mail.png';
import iconPinterest from '../../../images/icons/Pinterest.png';
import iconShare from '../../../images/icons/share.png';
import iconTwitter from '../../../images/icons/Twitter.png';

import {
  ShareWrapper,
  ShareList,
  IconList,
  Icon,
  IconContainer,
} from './styles.js';
import {
  Container,
  PreviewImageWrapper,
  BodyWrap,
} from '../EmailForm/styles.js';

import { StyledArticle, FormWrap } from '../../layoutComponents';

const SendConfirmation = ({
  data: { primary },
  shareData,
  img,
  imgData,
  imgDataFront,
  imgDataBack,
  setActiveStep,
  metaDescription,
  imgId,
}) => {
  const [stateImageData, setStateImageData] = useState(imgData);
  const [stateImageDataFront, setStateImageDataFront] = useState(imgDataFront);
  const [stateImageDataBack, setStateImageDataBack] = useState(imgDataBack);
  useEffect(() => {
    if (typeof window !== 'undefined' && window) {
      if (window.location.search.indexOf('qs=') > -1) {
        imgToBase64(setStateImageData, img);
        imgToBase64(
          setStateImageDataFront,
          img.split('.jpg').join('Front.jpg'),
        );

        imgToBase64(setStateImageDataBack, img.split('.jpg').join('Back.jpg'));

        sendPageView('nodfrom2050ecard_sharePage', '', 'Confirmation Share');
      } else {
        sendPageView('nodfrom2050ecard_step_4', '', 'Confirmation');
      }
    }
  }, []);
  const imgToBase64 = (stateTarget, imgSrc) => {
    const img = new Image(); // Create new img element
    img.crossOrigin = 'Anonymous';
    img.addEventListener(
      'load',
      () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = img.width;
        canvas.height = img.height;

        img.crossOrigin = 'anonymous';

        ctx.drawImage(img, 0, 0);

        const convertedImgData = canvas.toDataURL('image/jpeg', 1.0);
        stateTarget(convertedImgData);
      },
      false,
    );
    img.src = `${imgSrc}?cb=${uuidv4()}`; // Set source path
  };

  // const convertedImgDataBack = fetch(imgData)
  //   .then(function (res) {
  //     return res.arrayBuffer();
  //   })
  //   .then(function (buf) {
  //     imgDataBack = imgToBase64(
  //       new File([buf], 'nodfrom2050.jpg', {
  //         type: 'image/jpeg',
  //       }),
  //     );
  //
  //     console.log('imgDataBack', imgDataBack);
  //   });
  const siteUrl = 'https://nodfrom2050.ca';
  return (
    <Container>
      <PreviewImageWrapper>
        <img src={img || imgData} />
      </PreviewImageWrapper>
      <BodyWrap>
        <FormWrap as="div">
          <StyledArticle>
            <RichText render={primary.confirmation_title} />
            <RichText render={primary.page_copy} />
          </StyledArticle>
          <ShareWrapper>
            {primary.button_label_create_another && (
              <ButtonWrapper>
                <Button
                  handleClick={(e) => {
                    e.preventDefault();
                    setActiveStep(0);
                  }}
                  pageEventAction="confirmation_button_create_another"
                >
                  {primary.button_label_create_another}
                </Button>
              </ButtonWrapper>
            )}
            <ShareList>
              <li>
                {primary.button_label_share_site}
                <IconList>
                  {navigator.canShare && (
                    <Icon content={`Share ${primary.button_label_share_site}`}>
                      <IconContainer
                        onClick={() => {
                          navigator.share({
                            title: 'Nod from 2050',
                            text: metaDescription,
                            url: siteUrl,
                          });

                          trackGtagCustomEvent({
                            category: 'nodfrom2050ecard',
                            action: 'share_icon_share_nodfrom2050',
                            label: 'clicked',
                          });
                        }}
                      >
                        <img src={iconShare} />
                      </IconContainer>
                    </Icon>
                  )}

                  <Icon
                    content={`Share ${primary.button_label_share_site} to Facebook`}
                  >
                    <IconContainer>
                      <a
                        href="https://www.facebook.com/sharer.php?u=https://nodfrom2050.ca"
                        onClick={() => {
                          trackGtagCustomEvent({
                            category: 'nodfrom2050ecard',
                            action: 'share_icon_facebook_nodfrom2050',
                            label: 'clicked',
                          });
                        }}
                        target="_blank"
                      >
                        <img src={iconFacebook} />
                      </a>
                    </IconContainer>
                  </Icon>
                  <Icon
                    content={`Share ${primary.button_label_share_site} to Twitter`}
                  >
                    <IconContainer>
                      <a
                        href={`https://twitter.com/intent/tweet?text=${metaDescription}&url=${siteUrl}`}
                        onClick={() => {
                          trackGtagCustomEvent({
                            category: 'nodfrom2050ecard',
                            action: 'share_icon_twitter_nodfrom2050',
                            label: 'clicked',
                          });
                        }}
                        target="_blank"
                      >
                        <img src={iconTwitter} />
                      </a>
                    </IconContainer>
                  </Icon>

                  <Icon
                    content={`Share ${primary.button_label_share_site} to Pinterest`}
                  >
                    <IconContainer>
                      <a
                        href={`http://pinterest.com/pin/create/button/?url=https://nodfrom2050.ca&description=${metaDescription}`}
                        onClick={() => {
                          trackGtagCustomEvent({
                            category: 'nodfrom2050ecard',
                            action: 'share_icon_pinterest_nodfrom2050',
                            label: 'clicked',
                          });
                        }}
                        target="_blank"
                      >
                        <img src={iconPinterest} />
                      </a>
                    </IconContainer>
                  </Icon>
                </IconList>
              </li>
              <li>
                {primary.button_label_share_postcard}
                <IconList>
                  <Icon
                    content={`Download ${primary.button_label_share_postcard}`}
                  >
                    <IconContainer>
                      <a
                        href={`${stateImageData}`}
                        download="nodfrom2050.jpg"
                        onClick={() => {
                          trackGtagCustomEvent({
                            category: 'nodfrom2050ecard',
                            action: 'share_icon_download_nod',
                            label: 'clicked',
                          });
                        }}
                        target="_blank"
                      >
                        <img src={iconDownload} />
                      </a>
                    </IconContainer>
                  </Icon>

                  {navigator.canShare && (
                    <Icon
                      content={`Share ${primary.button_label_share_postcard}`}
                    >
                      <IconContainer
                        onClick={() => {
                          fetch(imgData || img)
                            .then(function (res) {
                              return res.arrayBuffer();
                            })
                            .then(function (buf) {
                              return new File([buf], 'nodfrom2050.jpg', {
                                type: 'image/jpeg',
                              });
                            })
                            .then(function (file) {
                              const filesArray = [file];
                              const shareData = { files: filesArray };
                              if (
                                navigator.canShare &&
                                navigator.canShare(shareData)
                              ) {
                                // Adding title afterwards as navigator.canShare just
                                // takes files as input
                                shareData.title = 'Nod From 2050';

                                navigator
                                  .share(shareData)
                                  .then(() =>
                                    console.log('Share was successful.'),
                                  )
                                  .catch((error) =>
                                    console.log('Sharing failed', error),
                                  );
                              } else {
                                console.log(
                                  "Your system doesn't support sharing files.",
                                );
                              }
                            });

                          trackGtagCustomEvent({
                            category: 'nodfrom2050ecard',
                            action: 'share_icon_share_nod',
                            label: 'clicked',
                          });
                        }}
                      >
                        <img src={iconShare} />
                      </IconContainer>
                    </Icon>
                  )}
                  <Icon
                    content={`Share ${primary.button_label_share_postcard} to Facebook`}
                  >
                    <IconContainer>
                      <a
                        href={`https://www.facebook.com/sharer.php?u=https://nodfrom2050.ca/eng-ca/?qs=${imgId}`}
                        onClick={() => {
                          trackGtagCustomEvent({
                            category: 'nodfrom2050ecard',
                            action: 'share_icon_facebook_nod',
                            label: 'clicked',
                          });
                        }}
                        target="_blank"
                      >
                        <img src={iconFacebook} />
                      </a>
                    </IconContainer>
                  </Icon>
                  <Icon
                    content={`Share ${primary.button_label_share_postcard} to Twitter`}
                  >
                    <IconContainer>
                      <a
                        href={`https://twitter.com/intent/tweet?text=${metaDescription}&url=https://nodfrom2050.ca/eng-ca/?qs=${imgId}`}
                        onClick={() => {
                          trackGtagCustomEvent({
                            category: 'nodfrom2050ecard',
                            action: 'share_icon_twitter_nod',
                            label: 'clicked',
                          });
                        }}
                        target="_blank"
                      >
                        <img src={iconTwitter} />
                      </a>
                    </IconContainer>
                  </Icon>
                  <Icon
                    content={`Share ${primary.button_label_share_postcard} to Pinterest`}
                  >
                    <IconContainer>
                      <a
                        href={`http://pinterest.com/pin/create/button/?url=https://nodfrom2050.ca/eng-ca/?qs=${imgId}&description=${metaDescription}`}
                        onClick={() => {
                          trackGtagCustomEvent({
                            category: 'nodfrom2050ecard',
                            action: 'share_icon_pinterest_nod',
                            label: 'clicked',
                          });
                        }}
                        target="_blank"
                      >
                        <img src={iconPinterest} />
                      </a>
                    </IconContainer>
                  </Icon>
                </IconList>
              </li>
              <li>
                {primary.button_label_share_front}

                <IconList>
                  <Icon
                    content={`Download ${primary.button_label_share_front}`}
                  >
                    <IconContainer>
                      <a
                        href={`${stateImageDataFront}`}
                        download="nodfrom2050Front.jpg"
                        onClick={() => {
                          trackGtagCustomEvent({
                            category: 'nodfrom2050ecard',
                            action: 'share_icon_download_front',
                            label: 'clicked',
                          });
                        }}
                        target="_blank"
                      >
                        <img src={iconDownload} />
                      </a>
                    </IconContainer>
                  </Icon>

                  {navigator.canShare && (
                    <Icon content={`Share ${primary.button_label_share_front}`}>
                      <IconContainer
                        onClick={() => {
                          fetch(
                            imgDataFront || img.split('.jpg').join('Front.jpg'),
                          )
                            .then(function (res) {
                              return res.arrayBuffer();
                            })
                            .then(function (buf) {
                              return new File([buf], 'nodfrom2050.jpg', {
                                type: 'image/jpeg',
                              });
                            })
                            .then(function (file) {
                              const filesArray = [file];
                              const shareData = { files: filesArray };
                              if (
                                navigator.canShare &&
                                navigator.canShare(shareData)
                              ) {
                                // Adding title afterwards as navigator.canShare just
                                // takes files as input
                                shareData.title = 'Nod From 2050';

                                navigator
                                  .share(shareData)
                                  .then(() =>
                                    console.log('Share was successful.'),
                                  )
                                  .catch((error) =>
                                    console.log('Sharing failed', error),
                                  );
                              } else {
                                console.log(
                                  "Your system doesn't support sharing files.",
                                );
                              }
                            });

                          trackGtagCustomEvent({
                            category: 'nodfrom2050ecard',
                            action: 'share_icon_share_front',
                            label: 'clicked',
                          });
                        }}
                      >
                        <img src={iconShare} />
                      </IconContainer>
                    </Icon>
                  )}
                </IconList>
              </li>

              <li>
                {primary.button_label_share_back}

                <IconList>
                  <Icon content={`Download ${primary.button_label_share_back}`}>
                    <IconContainer>
                      <a
                        href={`${stateImageDataBack}`}
                        download="nodfrom2050Back.jpg"
                        onClick={(e) => {
                          trackGtagCustomEvent({
                            category: 'nodfrom2050ecard',
                            action: 'share_icon_download_back',
                            label: 'clicked',
                          });
                        }}
                        target="_blank"
                      >
                        <img src={iconDownload} />
                      </a>
                    </IconContainer>
                  </Icon>

                  {navigator.canShare && (
                    <Icon content={`Share ${primary.button_label_share_back}`}>
                      <IconContainer
                        onClick={() => {
                          fetch(
                            imgDataBack || img.split('.jpg').join('Back.jpg'),
                          )
                            .then(function (res) {
                              return res.arrayBuffer();
                            })
                            .then(function (buf) {
                              return new File([buf], 'nodfrom2050.jpg', {
                                type: 'image/jpeg',
                              });
                            })
                            .then(function (file) {
                              const filesArray = [file];
                              const shareData = { files: filesArray };
                              if (
                                navigator.canShare &&
                                navigator.canShare(shareData)
                              ) {
                                // Adding title afterwards as navigator.canShare just
                                // takes files as input
                                shareData.title = 'Nod From 2050';

                                navigator
                                  .share(shareData)
                                  .then(() =>
                                    console.log('Share was successful.'),
                                  )
                                  .catch((error) =>
                                    console.log('Sharing failed', error),
                                  );
                              } else {
                                console.log(
                                  "Your system doesn't support sharing files.",
                                );
                              }
                            });

                          trackGtagCustomEvent({
                            category: 'nodfrom2050ecard',
                            action: 'share_icon_share_back',
                            label: 'clicked',
                          });
                        }}
                      >
                        <img src={iconShare} />
                      </IconContainer>
                    </Icon>
                  )}
                </IconList>
              </li>
            </ShareList>
          </ShareWrapper>
        </FormWrap>
      </BodyWrap>
    </Container>
  );
};

SendConfirmation.propTypes = {
  data: PropTypes.object,
};

SendConfirmation.defaultProps = {
  data: ``,
};

export default SendConfirmation;
