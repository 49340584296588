import PropTypes from 'prop-types';
import React from 'react';

import { StyledButtonWrapper } from './styles';

const ButtonWrapper = ({ children, className }) => (
  <StyledButtonWrapper className={`${className || ''} buttonWrapper`}>
    {children}
  </StyledButtonWrapper>
);
ButtonWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

ButtonWrapper.defaultProps = {};

export default ButtonWrapper;
