import styled from 'styled-components';

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: var(--margin-tb);
  @media screen and (max-width: 1024px) {
    margin-top: Calc(var(--margin-tb) * 0.5);
  }
  button {
    margin-right: var(--margin-lr);
    &:last-child {
      margin-right: 0;
    }
  }
`;
