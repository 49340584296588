import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import { Checkbox } from '@rmwc/checkbox';
import '@rmwc/checkbox/styles';

import { Tooltip } from '@rmwc/tooltip';
import '@rmwc/tooltip/styles';

import { RichText, Elements } from 'prismic-reactjs';
import Button from '../../ui/Button';

import ButtonWrapper from '../../ui/ButtonWrapper';
import vidSource from '../../../videos/shorelineHalftoneLoopF.mp4';
import vidSourcePlinth from '../../../videos/plinthMain.webm';

// import ReactFreezeframe from 'react-freezeframe';

import {
  Container,
  CarouselArea,
  WrapperCarousel,
  CarouselSlide,
  Wrapper,
  VideoMask,
  VideoElementWrapper,
  VideoElement,
  VideoPlinthElement,
  UIWrapper,
  InputCheckbox,
  StyledArticle,
} from './styles.js';
import { sendPageView, trackGtagCustomEvent } from '../../../utils/ga';

import { Context } from '../../context';

const Introduction = ({
  setIsValid,
  setActiveStep,
  data: { fields, primary },
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [selection, setSelection] = useState(null);
  const [stateOptIn, setStateOptIn] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const [isIOS, setIsIOS] = useState(false);
  const refAnimation = fields.map((x) => useRef(null));

  const context = useContext(Context);
  const handleClick = (idx, fluid, envelope) => {
    setIsValid(true);
    setSelection(idx);
  };

  useEffect(() => {
    // try to initialize things
    if (typeof window !== 'undefined' && window) {
      window.initGoogleAnalytics();

      sendPageView('nodfrom2050ecard_step_1', '', 'Introduction');
      const iosState =
        [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod',
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
      setIsIOS(iosState);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, [currentPage]);
  const handleNext = (e) => {
    e.preventDefault();
    setActiveStep(1);
  };

  const slideNext = () => {
    if (currentPage < fields.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const slidePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const propsWithUniqueKey = function (props, key) {
    return Object.assign(props || {}, { key });
  };
  const formatDateElement = (val, props, key) => {
    // const recursedInput =
    //   val.split('}}').length > 2
    //     ? formatInputElement(val.split('}}')[1], props, key)
    //     : '';

    if (!val) {
      return '';
    }

    const start = val.split('{{', 1)[0];
    const d = new Date();
    const middle = `${d.toLocaleString('en-ca', {
      month: 'long',
    })} ${d.getDate()}, 2050`;
    const end = val.substring(val.indexOf('}}') + 2, val.length);
    return React.createElement(
      'span',
      propsWithUniqueKey(props, key),
      start,
      React.createElement('span', {}, middle),
      end.indexOf('{{') > -1 ? formatInputElement(end) : end,
    );
  };
  const messagingResolver = (type, element, content, children, key) => {
    let props = {};
    switch (type) {
      // case Elements.paragraph: // Paragraph
      //   props = { className: 'paragraph-class' };
      //   const splitChildren = children.map((child) => {
      //     return child.map((val) => {
      //       return typeof val === 'string' && val.indexOf('{{') > -1
      //         ? React.createElement(
      //             'p',
      //             propsWithUniqueKey(props, key),
      //             formatDateElement(val, props, key),
      //           )
      //         : React.createElement('p', propsWithUniqueKey(props, key), val);
      //     });
      //   });
      case Elements.heading1: // Paragraph
        props = { className: 'heading-class' };
        const splitChildren = children.map((child) => {
          return child.map((val) => {
            return typeof val === 'string' && val.indexOf('{{') > -1
              ? React.createElement(
                  'h1',
                  propsWithUniqueKey(props, key),
                  formatDateElement(val, props, key),
                )
              : React.createElement('h1', propsWithUniqueKey(props, key), val);
          });
        });
        return splitChildren;
      default:
        // Always include a default that returns null
        return null;
    }
  };
  return (
    <Wrapper>
      <VideoElementWrapper>
        <VideoElement
          src={vidSource}
          muted
          autoPlay
          loop
          playsInline
          onLoadedData={(e) => {
            setIsLoaded(true);
          }}
        />
        <VideoMask
          className={`slide_${currentPage} ${
            isLoaded === true ? 'isLoaded' : ''
          }`}
        />
      </VideoElementWrapper>
      <CarouselArea currentPage={currentPage} isIOS={isIOS}>
        <WrapperCarousel slideLength={fields.length} currentPage={currentPage}>
          {fields.map((field, idx) => (
            <CarouselSlide
              key={idx}
              selected={idx === selection}
              className={`slide_${idx}`}
            >
              {currentPage === 1 && isIOS !== true && (
                <VideoPlinthElement
                  src={vidSourcePlinth}
                  playsInline
                  muted
                  autoPlay
                  loop
                  src={vidSourcePlinth}
                />
              )}
              <RichText
                render={field.slide_title}
                htmlSerializer={messagingResolver}
              />
              <StyledArticle>
                <RichText
                  render={field.slide_copy}
                  htmlSerializer={messagingResolver}
                />
                <UIWrapper>
                  {field.optInCopy && (
                    <>
                      <Checkbox
                        label={RichText.asText(field.optInCopy)}
                        checked={stateOptIn}
                        onChange={(evt) => {
                          setStateOptIn(!!evt.currentTarget.checked);
                          trackGtagCustomEvent({
                            category: 'nodfrom2050ecard',
                            action: 'introduction_optin_checked',
                            label: !!evt.currentTarget.checked,
                          });
                        }}
                        className="checkboxOptin"
                      />
                    </>
                  )}
                </UIWrapper>
                <ButtonWrapper>
                  {field.label_button_previous && (
                    <Button
                      handleClick={() => slidePrevious()}
                      pageEventAction="introduction_button_previous"
                    >
                      {field.label_button_previous}
                    </Button>
                  )}
                  {field.label_button_create && (
                    <Button
                      handleClick={handleNext}
                      pageEventAction="introduction_button_create"
                    >
                      {field.label_button_create}
                    </Button>
                  )}

                  {field.label_button_next && (
                    <Tooltip
                      content="Checkbox is required"
                      activateOn={stateOptIn ? [] : ['hover', 'focus']}
                    >
                      <div style={{ display: 'block' }}>
                        <Button
                          disabled={!stateOptIn}
                          handleClick={() => slideNext()}
                          pageEventAction="introduction_button_next"
                        >
                          {field.label_button_next}
                        </Button>
                      </div>
                    </Tooltip>
                  )}
                  {field.label_button_create_wizard && (
                    <Button
                      handleClick={() => {
                        context.dialogHandler(0);
                      }}
                      pageEventAction="introduction_button_helpmenod"
                    >
                      {field.label_button_create_wizard}
                    </Button>
                  )}
                </ButtonWrapper>
              </StyledArticle>
            </CarouselSlide>
          ))}
          {primary.bg_imageSharp && (
            <Container>
              <Img fluid={primary.bg_imageSharp.childImageSharp.fluid} />
            </Container>
          )}
        </WrapperCarousel>
      </CarouselArea>
    </Wrapper>
  );
};

Introduction.propTypes = {
  data: PropTypes.shape({
    fields: PropTypes.array,
  }).isRequired,
  setIsValid: PropTypes.func.isRequired,
};

export default Introduction;
