import React, { useEffect, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { RichText } from 'prismic-reactjs';

import _ from 'lodash';

import { v4 as uuidv4 } from 'uuid';
import { DropTarget, DragDropContainer } from 'react-drag-drop-container';
import { TextField } from '@rmwc/textfield';
import { Tooltip } from '@rmwc/tooltip';
import { CircularProgress } from '@rmwc/circular-progress';

import { Slider } from '@rmwc/slider';
import Button from '../../ui/Button';
import ButtonWrapper from '../../ui/ButtonWrapper';

import '@rmwc/tooltip/styles';
import '@rmwc/textfield/styles';
import '@rmwc/slider/styles';
import '@rmwc/circular-progress/styles';

import { Context } from '../../context';
import { sendPageView, trackGtagCustomEvent } from '../../../utils/ga';
import StickerPicker from '../StickerPicker';
import PatchPicker from '../PatchPicker';
import Patches from '../Patches';
import {
  Container,
  FieldWrapper,
  Label,
  Input,
  StyledTextArea,
  EditArea,
  DeleteStickerArea,
  RequiredFields,
  CharacterCount,
  CarouselSlide,
  DesignArea,
  CardImageWrapper,
  CardMessageFront,
  CardSubtitleFront,
  CardMessageBack,
  CardSubtitleBack,
  CardAddressBack,
  CardBack,
  WrapperRow,
  UIRow,
  StampFrameWrapper,
  StampWrapper,
  DroppedItemLayer,
  CardFront,
  Sticker,
  StyledDialogTitle,
  StyledSelect,
} from './styles';

import {
  StyledArticle,
  FormWrap,
  StyledTextField,
} from '../../layoutComponents';

const CardCreator = ({
  data,
  setIsValid,
  activeStep,
  setActiveStep,
  patches,
  patchAdd,
  patchRemove,
  className,
  siteBG,
  getImageOfCard,
  setStatePostcardMessageData,
}) => {
  const context = useContext(Context);
  // debounce for updating sliders etc
  const delayedCallback = useCallback(
    _.debounce((callback, q) => callback(q), 500),
    [],
  );
  const [stateFrontTextPositions, setStateFrontTextPositions] = useState({
    FRONT_MESSAGE: { top: '50%', left: '50%' },
    FRONT_SUBTITLE: { top: '25%', left: '50%' },
  });
  const [stateDraggableClones, setStateDraggableClones] = useState({
    DROPPED_STICKER: [],
  });

  const [stateMessageFront, setStateMessageFront] = useState('');
  const [stateSubtitleFront, setStateSubtitleFront] = useState('');
  const [stateMessageBack, setStateMessageBack] = useState('');
  const [stateSubtitleBack, setStateSubtitleBack] = useState('');

  const [stateImgStamp, setStateImgStamp] = useState(null);
  const [stateImgStampFrame, setStateImgStampFrame] = useState(null);
  const [stateImgWallpaper, setStateImgWallpaper] = useState(null);
  const [stateImgFront, setStateImgFront] = useState(null);

  const [stateFrontSubtitleFont, setStateFrontSubtitleFont] = useState('');
  const [stateFrontSubtitleColor, setStateFrontSubtitleColor] = useState(
    '#000000',
  );

  const [stateFrontSubtitleSize, setStateFrontSubtitleSize] = useState(48);
  const [stateFrontMessageFont, setStateFrontMessageFont] = useState('');
  const [stateFrontMessageColor, setStateFrontMessageColor] = useState(
    '#000000',
  );
  const [stateFrontMessageSize, setStateFrontMessageSize] = useState(62);
  const [currentPage, setCurrentPage] = useState(0);
  const killPatch = (id) => {};

  const [stateFrontImageStickers, setStateFrontImageStickers] = useState(
    data.primary.image_group_front_image.images,
  );

  const [
    stateWallpaperImageStickers,
    setStateWallpaperImageStickers,
  ] = useState(data.primary.image_group_front_image.images);

  const [stateFrontStickers, setStateFrontStickers] = useState(
    data.primary.image_group_front_stickers.images,
  );

  const [stateBackStamp, setStateBackStamp] = useState(
    data.primary.image_group_stamp.images,
  );

  const [stateBackStampFrames, setStateBackStampFrames] = useState(
    data.primary.image_group_stampframe.images,
  );
  useEffect(() => {
    sendPageView('nodfrom2050ecard_step_2', '', 'Card Creator');
    /* Randomise values sent to sticker picker */
    const stampSlice = [...data.primary.image_group_stamp.images]
      .slice(1, data.primary.image_group_stamp.images.length)
      .sort(() => Math.random() - 0.5);
    setStateBackStamp([
      data.primary.image_group_stamp.images[0],
      ...stampSlice,
    ]);

    setStateBackStampFrames(
      [...data.primary.image_group_stampframe.images].sort(
        () => Math.random() - 0.5,
      ),
    );
    setStateFrontImageStickers(
      [...data.primary.image_group_front_image.images].sort(
        () => Math.random() - 0.5,
      ),
    );
    setStateWallpaperImageStickers(
      [...data.primary.image_group_front_wallpaper.images].sort(
        () => Math.random() - 0.5,
      ),
    );
    setStateFrontStickers(
      [...data.primary.image_group_front_stickers.images].sort(
        () => Math.random() - 0.5,
      ),
    );
    context.dialogResponseHandler.postcard = (val) => {
      if (val.indexOf('Feeling more') === 0) {
        setPostcardValues(POSTCARD_PRESET.FEELING_MORE);
      } else if (val.indexOf('Feeling like') === 0) {
        setPostcardValues(POSTCARD_PRESET.FEELING_LIKE);
      } else if (val.indexOf('Being the') === 0) {
        setPostcardValues(POSTCARD_PRESET.BEING_THE);
      } else if (val.indexOf('Fear of') === 0) {
        setPostcardValues(POSTCARD_PRESET.FOMO);
      } else if (val.indexOf('My friends') === 0) {
        setPostcardValues(POSTCARD_PRESET.MY_FRIENDS);
      } else if (val.indexOf('Vibes with') === 0) {
        setPostcardValues(POSTCARD_PRESET.VIBES_WITH);
      } else if (val.indexOf('Being my') === 0) {
        setPostcardValues(POSTCARD_PRESET.BEING_MY);
      }
      // setDefaults(val);
    };
    context.dialogResponseHandler.messaging = (val) => {
      setStateMessageBack(val);
    };
    context.dialogResponseHandler.stampPhoto = (val) => {
      const newPath = {
        imageSharp: { childImageSharp: { fluid: { src: val, srcSet: val } } },
      };
      data.primary.image_group_stamp.images[0].imageSharp.childImageSharp.fluid.src = val;
      data.primary.image_group_stamp.images[0].imageSharp.childImageSharp.fluid.srcSet = val;
      data.primary.image_group_stamp.images[0].image.src = val;

      setStateImgStamp({
        childImageSharp: { fluid: { src: val } },
      });
    };
  }, []);
  const POSTCARD_PRESET = {
    RESET: {
      textPositions: {
        FRONT_MESSAGE: { top: '50%', left: '50%' },
        FRONT_SUBTITLE: { top: '25%', left: '50%' },
      },
      clones: [],
      messageFront: '',
      subtitleFront: '',
      messageBack: '',
      subtitleBack: '',
      imgStamp: null,
      imgStampFrame: null,
      imgWallpaper: null,
      imgFront: null,
      frontSubtitleFont: '',
      frontSubtitleColor: '#000000',
      frontSubtitleSize: 48,
      frontMessageFont: '',
      frontMessageColor: '#000000',
      frontMessageSize: 62,
      currentPage: 0,
    },
    FEELING_MORE: {
      textPositions: {
        FRONT_MESSAGE: { top: '65%', left: '11%' },
        FRONT_SUBTITLE: { top: '15%', left: '27%' },
      },
      clones: [],
      messageFront: 'Greetings\nfrom\n2050!',
      subtitleFront: 'You get\nthings done!',
      messageBack:
        "Dear Younger Me,\n\nI'm so happy and grateful that I stood up for myself and quit vaping.",
      subtitleBack: 'You made your dreams real!',
      imgStamp: [1, 2, 3, 4, 6, 7, 8],
      imgStampFrame: [2, 3, 5, 6],
      imgWallpaper: [9],
      imgFront: [8, 9], // patch.imageSharp//data.primary.image_group_stamp.images
      frontSubtitleFont: 'Playfair Display',
      frontSubtitleColor: '#FFF',
      frontSubtitleSize: 34,
      frontMessageFont: 'Bastille',
      frontMessageColor: '#FFF',
      frontMessageSize: 62,
    },
    FEELING_LIKE: {
      textPositions: {
        FRONT_MESSAGE: { top: '24px', left: '111px' },
        FRONT_SUBTITLE: { top: '81px', left: '18px' },
      },
      clones: [],
      messageFront: 'Greetings from 2050!',
      subtitleFront: 'You got this!',
      messageBack:
        "Dear Younger Me,\n\nI'm so happy and grateful that I no longer feel the urge to vape.",
      subtitleBack: 'You made it happen!',
      imgStamp: [1, 2, 3, 4, 6, 7, 8],
      imgStampFrame: [2, 3, 5, 6],
      imgWallpaper: [0, 1, 2, 3, 4, 5],
      imgFront: [11, 12],
      frontSubtitleFont: 'Halleyo',
      frontSubtitleColor: '#FFFFFF',
      frontSubtitleSize: 62,
      frontMessageFont: 'Playfair Display',
      frontMessageColor: '#FFFFFF',
      frontMessageSize: 28,
    },
    BEING_THE: {
      textPositions: {
        FRONT_MESSAGE: { top: '95px', left: '55px' },
        FRONT_SUBTITLE: { top: '40px', left: '14px' },
      },
      clones: [],
      messageFront: 'Greetings from 2050!',
      subtitleFront: 'You inspire me!',
      messageBack:
        'Dear Younger Me,\n\nI am so happy and grateful for the strong new me who no longer vapes.',
      subtitleBack: 'You are the best!',
      imgStamp: [1, 2, 3, 4, 6, 7, 8],
      imgStampFrame: [2, 3, 5, 6],
      imgWallpaper: [0, 1, 2, 3, 4, 5],
      imgFront: [13, 14, 15],
      frontSubtitleFont: 'Nature Spirit',
      frontSubtitleColor: '#000000',
      frontSubtitleSize: 48,
      frontMessageFont: 'Veronica',
      frontMessageColor: '#000000',
      frontMessageSize: 62,
    },
    FOMO: {
      textPositions: {
        FRONT_MESSAGE: { top: '466px', left: '19px' },
        FRONT_SUBTITLE: { top: '35px', left: '21px' },
      },
      clones: [],
      messageFront: 'Greetings from\n2050!',
      subtitleFront: "You're my champ!",
      messageBack: 'Dear Younger Me,\n\nI broke free from my vaping device.',
      subtitleBack: 'Your choices are great!',
      imgStamp: [1, 2, 3, 4, 6, 7, 8],
      imgStampFrame: [2, 3, 5, 6],
      imgWallpaper: [0, 1, 2, 3, 4, 5],
      imgFront: [5, 6, 7],
      frontSubtitleFont: 'Chinese Rocks',
      frontSubtitleColor: '#FFFFFF',
      frontSubtitleSize: 48,
      frontMessageFont: 'Playfair Display',
      frontMessageColor: '#FFFFFF',
      frontMessageSize: 52,
    },
    MY_FRIENDS: {
      textPositions: {
        FRONT_MESSAGE: { top: '466px', left: '19px' },
        FRONT_SUBTITLE: { top: '35px', left: '21px' },
      },
      clones: [],
      messageFront: 'Greetings from\n2050!',
      subtitleFront: 'You rock!',
      messageBack: 'Dear Younger Me,\n\nI am so proud that I quit when I did.',
      subtitleBack: 'You make me proud!',
      imgStamp: [1, 2, 3, 4, 6, 7, 8],
      imgStampFrame: [2, 3, 5, 6],
      imgWallpaper: [0, 1, 2, 3, 4, 5],
      imgFront: [1],
      frontSubtitleFont: 'Chinese Rocks',
      frontSubtitleColor: '#FFFFFF',
      frontSubtitleSize: 48,
      frontMessageFont: 'Playfair Display',
      frontMessageColor: '#FFFFFF',
      frontMessageSize: 52,
    },
    VIBES_WITH: {
      textPositions: {
        FRONT_MESSAGE: { top: '466px', left: '19px' },
        FRONT_SUBTITLE: { top: '35px', left: '21px' },
      },
      clones: [],
      messageFront: 'Greetings from\n2050!',
      subtitleFront: "You've so much to\ncelebrate!",
      messageBack:
        'Dear Younger Me,\n\nI am so happy and grateful that I decided and quit vaping.',
      subtitleBack: "You're my hero!",
      imgStamp: [1, 2, 3, 4, 6, 7, 8],
      imgStampFrame: [2, 3, 5, 6],
      imgWallpaper: [0, 1, 2, 3, 4, 5],
      imgFront: [2, 3, 4],
      frontSubtitleFont: 'Chinese Rocks',
      frontSubtitleColor: '#FFFFFF',
      frontSubtitleSize: 48,
      frontMessageFont: 'Playfair Display',
      frontMessageColor: '#000000',
      frontMessageSize: 52,
    },
    BEING_MY: {
      textPositions: {
        FRONT_MESSAGE: { top: '466px', left: '19px' },
        FRONT_SUBTITLE: { top: '46px', left: '70px' },
      },
      clones: [],
      messageFront: 'Greetings from\n2050!',
      subtitleFront: 'Here to\ncelebrate you!',
      messageBack:
        'Dear Younger Me,\n\nI am so grateful that vaping is no longer a part of my identity.',
      subtitleBack: "We're here because of you!",
      imgStamp: [1, 2, 3, 4, 6, 7, 8],
      imgStampFrame: [2, 3, 5, 6],
      imgWallpaper: [0, 1, 2, 3, 4, 5],
      imgFront: [16, 17, 18],
      frontSubtitleFont: 'Chinese Rocks',
      frontSubtitleColor: '#FFFFFF',
      frontSubtitleSize: 48,
      frontMessageFont: 'Playfair Display',
      frontMessageColor: '#000000',
      frontMessageSize: 52,
    },
  };
  const setPostcardValues = (preset) => {
    setStateFrontTextPositions(preset.textPositions);
    setStateDraggableClones({ DROPPED_STICKER: preset.clones || [] });
    setStateMessageFront(preset.messageFront);
    setStateSubtitleFront(preset.subtitleFront);
    setStateMessageBack(preset.messageBack);
    setStateSubtitleBack(preset.subtitleBack);
    setStateImgStamp(
      preset.imgStamp
        ? data.primary.image_group_stamp.images[_.shuffle(preset.imgStamp)[0]]
            .imageSharp
        : null,
    );
    setStateImgStampFrame(
      preset.imgStampFrame
        ? data.primary.image_group_stampframe.images[
            _.shuffle(preset.imgStampFrame)[0]
          ].imageSharp
        : null,
    );
    setStateImgWallpaper(
      preset.imgWallpaper
        ? data.primary.image_group_front_wallpaper.images[
            _.shuffle(preset.imgWallpaper)[0]
          ].imageSharp
        : null,
    );

    setStateImgFront(
      preset.imgFront
        ? data.primary.image_group_front_image.images[
            _.shuffle(preset.imgFront)[0]
          ].imageSharp
        : null,
    );
    setStateFrontSubtitleFont(preset.frontSubtitleFont);
    setStateFrontSubtitleColor(preset.frontSubtitleColor);
    setStateFrontSubtitleSize(preset.frontSubtitleSize);
    setStateFrontMessageFont(preset.frontMessageFont);
    setStateFrontMessageColor(preset.frontMessageColor);
    setStateFrontMessageSize(preset.frontMessageSize);
    if (preset.currentPage !== undefined) {
      setCurrentPage(preset.currentPage);
    }
  };

  const checkValid = () => {
    return setIsValid(false);
    setIsValid(true);
  };

  const mobileScalingModifier =
    window.innerWidth > 768 && window.innerWidth <= 1320
      ? 1.35
      : window.innerWidth <= 768
      ? 2
      : 1;
  const CHARACTER_LIMIT = 60;
  const LINE_LIMIT = 2;
  const handleHitPatch = ({ x, y, dragData, target }) => {
    console.log('handleHitPatch');
    const { patch, type } = dragData;
    const patchOptions = {
      ...dragData,
      id: `patch-${uuidv4()}`,
      image: dragData.patch,
      x,
      targetx: target.x,
      y,
      targety: target.y,
      killPatch: killPatch.bind(this),
    };
    switch (type) {
      case 'STAMP':
        setStateImgStamp(patch.imageSharp);
        break;
      case 'STAMP_FRAME':
        setStateImgStampFrame(patch.imageSharp);
        break;
      case 'FRONT_IMAGE':
        setStateImgFront(patch.imageSharp);
        break;
      case 'FRONT_WALLPAPER':
        setStateImgWallpaper(patch.imageSharp);
        break;
      default:
        patchAdd(patchOptions, dragData.PATCH_ID ? dragData.PATCH_ID : null);
    }
  };
  const onTextDrop = (item, monitor, targetDropElement) => {
    console.log('onTextDrop');
    const targetDropPosition = document
      .getElementById(targetDropElement)
      .getBoundingClientRect();
    const delta = monitor.getClientOffset();
    const deltaMouse = {
      x:
        monitor.getInitialClientOffset().x -
        monitor.getInitialSourceClientOffset().x -
        targetDropPosition.width / 2,
      y:
        monitor.getInitialClientOffset().y -
        monitor.getInitialSourceClientOffset().y -
        targetDropPosition.height / 2,
    };
    const left = `${
      Math.round(
        delta.x -
          targetDropPosition.x -
          targetDropPosition.width / 2 -
          deltaMouse.x,
      ) * mobileScalingModifier
    }px`;
    const top = `${
      Math.round(
        delta.y -
          targetDropPosition.y -
          targetDropPosition.height / 2 -
          deltaMouse.y,
      ) * mobileScalingModifier
    }px`;
    switch (item.type) {
      case 'FRONT_MESSAGE':
        setStateFrontTextPositions({
          ...stateFrontTextPositions,
          ...{ FRONT_MESSAGE: { left, top } },
        });
        break;
      case 'FRONT_SUBTITLE':
        setStateFrontTextPositions({
          ...stateFrontTextPositions,
          ...{ FRONT_SUBTITLE: { left, top } },
        });
        break;
    }
  };
  const onStickerDrop = (item, monitor, targetDropElement) => {
    const { dragData } = item;
    const targetDropPosition = document
      .getElementById(targetDropElement)
      .getBoundingClientRect();
    const delta = monitor.getClientOffset();
    const deltaMouse = {
      x:
        monitor.getInitialClientOffset().x -
        monitor.getInitialSourceClientOffset().x -
        dragData.width / 2,
      y:
        monitor.getInitialClientOffset().y -
        monitor.getInitialSourceClientOffset().y -
        dragData.height / 2,
    };
    const left =
      Math.round(
        delta.x - targetDropPosition.x - dragData.width / 2 - deltaMouse.x,
      ) * mobileScalingModifier;
    const top =
      Math.round(
        delta.y - targetDropPosition.y - dragData.height / 2 - deltaMouse.y,
      ) * mobileScalingModifier;

    // Move an item if it's already dropped
    // otherwise, add a new duplicate
    const alreadyDropped = item.dragData.id !== undefined;
    if (alreadyDropped) {
      // update
      updateSticker(item.type, item.dragData.id, { left, top });
    } else {
      // create
      const newItem = {
        ...item.dragData,
        left,
        top,
        id: `sticker-${uuidv4()}`,
        type: `DROPPED_${item.type}`,
      };
      createSticker(`DROPPED_${item.type}`, newItem);
    }
    return undefined;
  };
  const onDropDesignArea = (item, monitor) => {
    if (item.dragData) {
      trackGtagCustomEvent({
        category: 'nodfrom2050ecard',
        action: `sticker_dropped_${item.type}`,
        label: item.dragData.imageSharp.childImageSharp.fluid.src,
      });
    }
    switch (item.type) {
      case 'STAMP':
        setStateImgStamp(
          item.dragData ? item.dragData.imageSharp : item.dragData,
        );
        break;
      case 'STAMP_FRAME':
        setStateImgStampFrame(
          item.dragData ? item.dragData.imageSharp : item.dragData,
        );
        break;
      case 'WALLPAPER':
        setStateImgWallpaper(
          item.dragData ? item.dragData.imageSharp : item.dragData,
        );
        break;
      case 'FRONT_IMAGE':
        setStateImgFront(
          item.dragData ? item.dragData.imageSharp : item.dragData,
        );
        break;
    }
    return undefined;
  };

  const onDropEditArea = (item, monitor) => {
    trackGtagCustomEvent({
      category: 'nodfrom2050ecard',
      action: `sticker_deleted_${item.type}`,
      label: item.dragData.imageSharp.childImageSharp.src,
    });
    deleteSticker(item.type, item.dragData.id);
  };

  const createSticker = (type, val) => {
    const newDroppedArray =
      stateDraggableClones[type].length === 0
        ? { [type]: [val] }
        : {
            [type]: [...stateDraggableClones[type], val],
          };
    setStateDraggableClones({
      ...stateDraggableClones,
      ...newDroppedArray,
    });
  };
  const getSticker = (type, id) => {
    const updatedDraggableClones = stateDraggableClones[type].filter(
      (existingItem) => {
        return existingItem.id === id;
      },
    );
    return updatedDraggableClones[0] || null;
  };
  const updateSticker = (type, id, val) => {
    const updatedState = stateDraggableClones[type].map(
      (existingItem, index) => {
        const revisedItem =
          existingItem.id === id ? { ...existingItem, ...val } : existingItem;
        return revisedItem;
      },
    );
    setStateDraggableClones({
      ...stateDraggableClones,
      ...{ [type]: updatedState },
    });
  };
  const deleteSticker = (type, id) => {
    const updatedDraggableClones = stateDraggableClones[type].filter(
      (existingItem) => {
        return existingItem.id !== id;
      },
    );
    setStateDraggableClones({
      ...stateDraggableClones,
      ...{ [type]: updatedDraggableClones },
    });
  };
  // TODO: add error state to the form fields
  const optionsFont = [
    {
      value: 'Playfair Display',
      label: 'Display',
    },
    {
      value: 'Halleyo',
      label: 'Flowy',
    },
    {
      value: 'Bastille',
      label: 'Future Cop',
    },
    {
      value: 'Veronica',
      label: 'Modern',
    },
    {
      value: 'Nature Spirit',
      label: 'Nature',
    },
    {
      value: 'Italo',
      label: 'Note It',
    },
    {
      value: 'Chinese Rocks',
      label: 'Rocks',
    },
    {
      value: 'Dads_recipe',
      label: 'Recipe',
    },
  ];

  const resetDialog = (e) => {
    e.preventDefault();
    const confirmResponse = confirm('Are you sure you want to reset your Nod?');
    if (confirmResponse) {
      setPostcardValues(POSTCARD_PRESET.RESET);
    }
  };
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, [currentPage]);
  useEffect(() => {
    // Reset page for moving from step 3 to 1 - 'back' button pressed
    if (activeStep === 1) {
      setCurrentPage(0);
    }
  }, [activeStep]);
  return (
    <Container
      siteBG={siteBG}
      className={`${className} page_${currentPage} activeStep_${activeStep}`}
    >
      <DesignArea
        id="design-area"
        wallpaper={
          stateImgWallpaper ? stateImgWallpaper.childImageSharp.fluid.src : null
        }
        accept={[
          'STAMP',
          'STAMP_FRAME',
          'WALLPAPER',
          'FRONT_IMAGE',
          'DROPPED_STICKER',
        ]}
        id="design-area"
        onDrop={onDropDesignArea}
        className={`designArea page_${currentPage}`}
      >
        <CardBack id="dropTargetCardBack" className="cardBack">
          <CardSubtitleBack
            id="subtitleBack"
            dangerouslySetInnerHTML={{
              __html: stateSubtitleBack.trim().split('\n').join('<br/>'),
            }}
          />
          <CardMessageBack
            id="messageBack"
            dangerouslySetInnerHTML={{
              __html: stateMessageBack.trim().split('\n').join('<br/>'),
            }}
          />
          <StampWrapper className="wrapperStamp">
            {stateImgStamp && (
              <img src={stateImgStamp.childImageSharp.fluid.src} />
            )}
          </StampWrapper>
          <StampFrameWrapper className="wrapperStampFrame">
            {stateImgStampFrame && (
              <img src={stateImgStampFrame.childImageSharp.fluid.src} />
            )}
          </StampFrameWrapper>
          <CardAddressBack id="addressBack">
            {data.primary.back_address && (
              <>{RichText.render(data.primary.back_address)}</>
            )}
          </CardAddressBack>
        </CardBack>
        {
          // Wrapper Front will be replaced
        }
        <CardFront
          accept={[
            'STICKER',
            'DROPPED_STICKER',
            'FRONT_MESSAGE',
            'FRONT_SUBTITLE',
          ]}
          className="cardFront"
          id="dropTargetCardFront"
          onDrop={(item, monitor) => {
            if (['FRONT_MESSAGE', 'FRONT_SUBTITLE'].indexOf(item.type) !== -1) {
              onTextDrop(item, monitor, 'dropTargetCardFront');
              trackGtagCustomEvent({
                category: 'nodfrom2050ecard',
                action: `textMessage_dropped_${item.type}`,
                label: `${item.type}`,
              });
            } else {
              onStickerDrop(item, monitor, 'dropTargetCardFront');
              trackGtagCustomEvent({
                category: 'nodfrom2050ecard',
                action: `sticker_dropped_${item.type}`,
                label: item.dragData.imageSharp.childImageSharp.fluid.src,
              });
            }

            return undefined;
          }}
        >
          <CardImageWrapper className="has-shadow">
            {stateImgFront && (
              <img src={stateImgFront.childImageSharp.fluid.src} />
            )}
          </CardImageWrapper>

          <DroppedItemLayer>
            {stateDraggableClones.DROPPED_STICKER &&
              stateDraggableClones.DROPPED_STICKER.map((item, idx) => {
                return (
                  <div>
                    <Sticker
                      dragData={item}
                      className={`sprite_back_${item.id}`}
                      type="DROPPED_STICKER"
                    >
                      {item.children}
                    </Sticker>
                  </div>
                );
              })}
          </DroppedItemLayer>
          <Sticker
            dragData={stateFrontTextPositions.FRONT_MESSAGE}
            type="FRONT_MESSAGE"
            className="frontDragMessage"
          >
            <CardMessageFront
              id="messageFront"
              dangerouslySetInnerHTML={{
                __html: stateMessageFront.trim().split('\n').join('<br/>'),
              }}
              color={stateFrontMessageColor}
              fontFamily={stateFrontMessageFont}
              fontSize={stateFrontMessageSize}
            />
          </Sticker>
          <Sticker
            dragData={stateFrontTextPositions.FRONT_SUBTITLE}
            type="FRONT_SUBTITLE"
            className="frontDragSubtitle"
          >
            <CardSubtitleFront
              id="subtitleFront"
              dangerouslySetInnerHTML={{
                __html: stateSubtitleFront.trim().split('\n').join('<br/>'),
              }}
              color={stateFrontSubtitleColor}
              fontFamily={stateFrontSubtitleFont}
              fontSize={stateFrontSubtitleSize}
            />
          </Sticker>
        </CardFront>
      </DesignArea>
      <EditArea
        id="edit-area"
        accept={['DROPPED_STICKER']}
        id="dropTargetEditArea"
        onDrop={onDropEditArea}
        className={`editArea page_${currentPage}`}
      >
        <DeleteStickerArea />
        <FormWrap>
          {currentPage === 0 && (
            <CarouselSlide key="slide_back">
              <StyledArticle className="styledArticle">
                {RichText.render(data.primary.header_card_back_slide)}
              </StyledArticle>
              {false && <>*
{data.primary.required_field}
</>}
              {data.primary.label_button_create_wizard && (
                <ButtonWrapper>
                  <Button
                    handleClick={(e) => {
                      e.preventDefault();
                      context.dialogHandler(0);
                    }}
                    pageEventAction="cardcreator_back_button_helpmenod"
                  >
                    {data.primary.label_button_create_wizard}
                  </Button>
                </ButtonWrapper>
              )}
              <FieldWrapper>
                {RichText.render(data.primary.label_back_subtitle)}
                <StyledTextField
                  label={null}
                  value={stateSubtitleBack}
                  characterCount
                  maxLength={80}
                  onChange={(e) => {
                    setStateSubtitleBack(e.target.value);
                  }}
                />
              </FieldWrapper>

              <FieldWrapper>
                {RichText.render(data.primary.label_back_message)}
                {data.primary.required_field}
                <WrapperRow>
                  <StyledTextField
                    outlined={false}
                    textarea
                    fullwidth
                    label=""
                    rows={8}
                    characterCount
                    value={stateMessageBack}
                    onChange={(e) => {
                      setStateMessageBack(e.target.value);
                    }}
                    className="rwmcTextArea"
                  />
                  {false && (
                    <StyledTextArea
                      value={stateMessageBack}
                      onChange={(e) => {
                        setStateMessageBack(e.target.value);
                      }}
                    />
                  )}
                  {data.primary.label_button_message_wizard && (
                    <Button
                      handleClick={(e) => {
                        e.preventDefault();
                        context.dialogHandler(2);
                      }}
                      pageEventAction="cardcreator_back_button_helpmewrite"
                    >
                      {data.primary.label_button_message_wizard}
                    </Button>
                  )}
                </WrapperRow>
              </FieldWrapper>

              <FieldWrapper>
                {RichText.render(data.primary.label_back_stamp)}
                {data.primary.image_group_stamp && (
                  <StickerPicker
                    snap={[0, 0]}
                    sizes={{
                      width: 95,
                      height: 114,
                    }}
                    numPatchesPerPanel={4}
                    patches={stateBackStamp}
                    scroll
                    selectable
                    type="STAMP"
                    onToggleSticker={(data) => {
                      if (data.index === 0 && data.dragData) {
                        context.dialogHandler(1);
                      }
                      onDropDesignArea(data);
                    }}
                  />
                )}
              </FieldWrapper>

              <FieldWrapper>
                {RichText.render(data.primary.label_back_stamp_frame)}
                {data.primary.image_group_stampframe && (
                  <StickerPicker
                    snap={[0, 0]}
                    sizes={{
                      width: 95,
                      height: 114,
                    }}
                    numPatchesPerPanel={3}
                    patches={stateBackStampFrames}
                    scroll
                    selectable
                    type="STAMP_FRAME"
                    onToggleSticker={onDropDesignArea}
                  />
                )}
              </FieldWrapper>
              <ButtonWrapper>
                {data.primary.label_button_reset && (
                  <Button
                    handleClick={resetDialog}
                    pageEventAction="cardcreator_back_button_reset"
                  >
                    {data.primary.label_button_reset}
                  </Button>
                )}
                {data.primary.label_button_forward && (
                  <Tooltip
                    content="You missed a required field above"
                    activateOn={
                      stateMessageBack.trim() === ''
                        ? ['hover', 'focus', 'click']
                        : []
                    }
                  >
                    <div>
                      <Button
                        handleClick={(e) => {
                          e.preventDefault();
                          setCurrentPage(1);
                        }}
                        disabled={stateMessageBack.trim() === ''}
                        pageEventAction="cardcreator_back_button_forward"
                      >
                        {data.primary.label_button_forward}
                      </Button>
                    </div>
                  </Tooltip>
                )}
              </ButtonWrapper>
            </CarouselSlide>
          )}
          {currentPage === 1 && (
            <CarouselSlide>
              <StyledArticle className="styledArticle">
                {RichText.render(data.primary.header_card_front_slide)}
              </StyledArticle>
              {data.primary.label_button_create_wizard && (
                <ButtonWrapper>
                  <Button
                    handleClick={(e) => {
                      e.preventDefault();
                      context.dialogHandler(0);
                    }}
                    pageEventAction="cardcreator_front_button_helpmenod"
                  >
                    {data.primary.label_button_create_wizard}
                  </Button>
                </ButtonWrapper>
              )}

              <FieldWrapper>
                {RichText.render(data.primary.label_front_image)}

                {data.primary.required_field}
                {data.primary.image_group_front_image && (
                  <StickerPicker
                    snap={[0, 0]}
                    sizes={{
                      width: 120,
                      height: 180,
                    }}
                    numPatchesPerPanel={3}
                    patches={stateFrontImageStickers}
                    scroll
                    selectable
                    type="FRONT_IMAGE"
                    onToggleSticker={onDropDesignArea}
                  />
                )}
              </FieldWrapper>

              <FieldWrapper>
                {RichText.render(data.primary.label_front_wallpaper)}
                {data.primary.image_group_front_wallpaper && (
                  <StickerPicker
                    snap={[0, 0]}
                    sizes={{
                      width: 120,
                      height: 180,
                    }}
                    numPatchesPerPanel={3}
                    patches={stateWallpaperImageStickers}
                    scroll
                    selectable
                    type="WALLPAPER"
                    onToggleSticker={onDropDesignArea}
                  />
                )}
              </FieldWrapper>

              <FieldWrapper>
                {RichText.render(data.primary.label_front_sticker)}
                {data.primary.image_group_front_stickers && (
                  <StickerPicker
                    snap={[0, 0]}
                    sizes={{
                      width: 120,
                      height: 120,
                    }}
                    contain
                    numPatchesPerPanel={3}
                    patches={stateFrontStickers}
                    scroll
                    type="STICKER"
                    targetKey="targetCard"
                  />
                )}
              </FieldWrapper>

              <FieldWrapper>
                {RichText.render(data.primary.label_front_subtitle)}

                <StyledTextField
                  outlined={false}
                  textarea
                  fullwidth
                  label=""
                  rows={4}
                  characterCount
                  value={stateSubtitleFront}
                  onChange={(e) => {
                    setStateSubtitleFront(e.target.value);
                  }}
                  className="rwmcTextArea"
                />
                {false && (
                  <StyledTextArea
                    value={stateSubtitleFront}
                    onChange={(e) => {
                      setStateSubtitleFront(e.target.value);
                    }}
                  />
                )}
                <UIRow className="fontFamilyColorInputGroup">
                  <StyledSelect
                    width={128}
                    label={data.primary.font_label}
                    options={optionsFont}
                    value={stateFrontSubtitleFont}
                    onChange={(e) => {
                      setStateFrontSubtitleFont(e.target.value);
                    }}
                    className="fontSelector"
                  />
                  <UIRow className="fontColorInputGroup">
                    <label>{data.primary.color_label}</label>
                    <input
                      type="color"
                      value={stateFrontSubtitleColor}
                      onChange={(e) => {
                        delayedCallback(
                          setStateFrontSubtitleColor,
                          e.target.value,
                        );
                      }}
                    />
                  </UIRow>
                </UIRow>
                <UIRow className="fontSliderGroup">
                  <label>
                    {`${data.primary.font_size_label} ${stateFrontSubtitleSize}`}
                  </label>
                  <Slider
                    onInput={(e) => {
                      delayedCallback(
                        setStateFrontSubtitleSize,
                        Math.floor(e.target.value),
                      );
                    }}
                    onChange={(e) => {
                      delayedCallback(
                        setStateFrontSubtitleSize,
                        Math.floor(e.target.value),
                      );
                    }}
                    value={stateFrontSubtitleSize}
                    min="16"
                    max="128"
                    steps="4"
                    className="sizeSlider"
                  />
                </UIRow>
              </FieldWrapper>

              <FieldWrapper>
                <div style={{ marginBottom: '1.45rem' }}>
                  {RichText.render(data.primary.label_front_title)}
                </div>
                <StyledTextField
                  outlined={false}
                  textarea
                  fullwidth
                  label=""
                  rows={4}
                  characterCount
                  value={stateMessageFront}
                  onChange={(e) => {
                    setStateMessageFront(e.target.value);
                  }}
                  className="rwmcTextArea"
                />
                {false && (
                  <StyledTextArea
                    value={stateMessageFront}
                    onChange={(e) => {
                      setStateMessageFront(e.target.value);
                    }}
                  />
                )}
                <UIRow className="fontFamilyColorInputGroup">
                  <StyledSelect
                    label={data.primary.font_label}
                    options={optionsFont}
                    value={stateFrontMessageFont}
                    onChange={(e) => {
                      setStateFrontMessageFont(e.target.value);
                    }}
                    className="fontSelector"
                  />

                  <UIRow className="fontColorInputGroup">
                    <label>{data.primary.color_label}</label>
                    <input
                      type="color"
                      value={stateFrontMessageColor}
                      onChange={(e) => {
                        delayedCallback(
                          setStateFrontMessageColor,
                          e.target.value,
                        );
                      }}
                    />
                  </UIRow>
                </UIRow>
                <UIRow className="fontSliderGroup">
                  <label>
                    {`${data.primary.font_size_label} ${stateFrontSubtitleSize}`}
                  </label>
                  <Slider
                    onInput={(e) => {
                      delayedCallback(
                        setStateFrontMessageSize,
                        Math.floor(e.target.value),
                      );
                    }}
                    onChange={(e) => {
                      delayedCallback(
                        setStateFrontMessageSize,
                        Math.floor(e.target.value),
                      );
                    }}
                    value={stateFrontMessageSize}
                    min="16"
                    max="128"
                    steps="4"
                    className="sizeSlider"
                  />
                </UIRow>
              </FieldWrapper>
              <ButtonWrapper>
                {data.primary.label_button_reset && (
                  <Button
                    handleClick={resetDialog}
                    pageEventAction="cardcreator_front_button_reset"
                  >
                    {data.primary.label_button_reset}
                  </Button>
                )}
                {data.primary.label_button_back && (
                  <Button
                    handleClick={(e) => {
                      e.preventDefault();
                      setCurrentPage(0);
                    }}
                    pageEventAction="cardcreator_front_button_back"
                  >
                    {data.primary.label_button_back}
                  </Button>
                )}
                {data.primary.label_button_create && (
                  <Tooltip
                    content="Please select a 'Nod Image' above"
                    activateOn={
                      stateImgFront === null ? ['hover', 'focus', 'click'] : []
                    }
                  >
                    <div>
                      <Button
                        handleClick={(e) => {
                          e.preventDefault();
                          setCurrentPage(2);
                          setStatePostcardMessageData({
                            stateSubtitleFront,
                            stateMessageFront,
                            stateSubtitleBack,
                            stateMessageBack,
                          });
                          if (typeof window !== undefined) {
                            window.scrollTo(0, 0);
                          }
                          setTimeout(() => {
                            getImageOfCard();

                            // setActiveStep(3, {
                            //   stateSubtitleFront,
                            //   stateMessageFront,
                            //   stateSubtitleBack,
                            //   stateMessageBack,
                            // });
                          }, 1000);
                        }}
                        disabled={stateImgFront === null}
                        pageEventAction="cardcreator_front_button_createnod"
                      >
                        {data.primary.label_button_create}
                      </Button>
                    </div>
                  </Tooltip>
                )}
              </ButtonWrapper>
            </CarouselSlide>
          )}
          {currentPage === 2 && (
            <CarouselSlide>
              <StyledArticle className="styledArticle">
                <h1>
                  Creating
                  <br />
                  Nod...
                </h1>
                <CircularProgress size={72} />
              </StyledArticle>
            </CarouselSlide>
          )}
        </FormWrap>
      </EditArea>
    </Container>
  );
};

const StyledCardCreator = styled(CardCreator)``;

CardCreator.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
  setIsValid: PropTypes.func.isRequired,
};

CardCreator.defaultProps = {
  className: ``,
};

export default StyledCardCreator;
