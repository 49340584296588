import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@rmwc/tooltip';
import '@rmwc/tooltip/styles';

const TooltipTimer = ({
  content,
  updateOnChange = false,
  open,
  time = 1000,
  children,
}) => {
  const [stateOpen, setStateOpen] = useState(true);
  useEffect(() => {
    setStateOpen(true);
    setTimeout(() => {
      setStateOpen(false);
    }, time);
  }, []);

  useEffect(() => {
    if (updateOnChange === true) {
      setStateOpen(true);
      setTimeout(() => {
        setStateOpen(false);
      }, time);
    }
  }, [children]);

  return (
    <Tooltip open={stateOpen} content={content}>
      {children}
    </Tooltip>
  );
};

export default TooltipTimer;
